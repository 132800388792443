import {Injectable} from '@angular/core';
import {BaseService, ErrorDto} from '../common/abstract/base-service';
import {catchError} from 'rxjs/operators';
import {CategoryResponse} from '../model/category/category-response';
import {HttpClient} from '@angular/common/http';
import {AppConstants} from '../app-constants';

@Injectable({
  providedIn: 'root'
})
export class CategoryService extends BaseService {

  private categoriesEndpoint = AppConstants.baseUrl + '/api/categories';

  constructor(private httpClient: HttpClient) {
    super();
  }

  public findCategories(errorDto:ErrorDto) {
    return this.httpClient.get<CategoryResponse>(this.categoriesEndpoint, {headers: AppConstants.acceptJson})
      .pipe(catchError((err) => this.handleError(err, errorDto)));
  }
}
