import {Component, OnDestroy, OnInit} from '@angular/core';
import {PaymentSharedService} from '../service/payment-shared.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {PaymentType} from '../model/delivery/payment-type';
import {HeaderDto} from '../model/header/header-dto';
import {HeaderSharedService} from '../service/header-shared.service';
import {AppConstants} from "../app-constants";

@Component({
    selector: 'app-delivery',
    templateUrl: './delivery.component.html',
    styleUrls: ['./delivery.component.scss']
})
export class DeliveryComponent implements OnInit, OnDestroy {

    private destroy$: Subject<boolean> = new Subject<boolean>();
    isTransferSelectedDefault = true;
    isProformaSelected = false;
    postOfficePrice = 125;
    // TODO will be reloaded from config
    defaultBankTransferPrice = 0;
    // TODO will be reloaded from config
    proformaPrice = 75;

    constructor(private deliveryService: PaymentSharedService,
                private headerSharedService: HeaderSharedService) {
    }

    private static getPrice(price: number) {
        return (price === 0) ? 'Zdarma' : price + 'Kč';
    }

    ngOnInit(): void {
        this.deliveryService.getPaymentObservable()
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: paymentType => {
                    switch (paymentType) {
                        case PaymentType.PROFORMA:
                            this.isTransferSelectedDefault = false;
                            this.isProformaSelected = true;
                            break;
                        default: {
                            this.isTransferSelectedDefault = true;
                            this.isProformaSelected = false;
                            break;
                        }
                    }
                },
                error: err => {
                    console.error('Error when obtain delivery', err);
                    this.setPaymentType(PaymentType.BANK_TRANSFER);
                }
            });
    }

    getProformaPrice() {
        return DeliveryComponent.getPrice(this.proformaPrice);
    }

    getBankTransferPrice() {
        return DeliveryComponent.getPrice(this.defaultBankTransferPrice);
    }

    applyProforma() {
        this.setPaymentType(PaymentType.PROFORMA);
        this.isProformaSelected = true;
        this.isTransferSelectedDefault = false;
    }

    applyTransfer() {
        this.setPaymentType(PaymentType.BANK_TRANSFER);
        this.isProformaSelected = false;
        this.isTransferSelectedDefault = true;
    }

    getPostOfficeImage() {
        return AppConstants.postOfficeDelivery;
    }

    private setPaymentType(paymentType: PaymentType) {
        this.deliveryService.changeValue(paymentType);
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.complete();
    }

    activeDeliveryAddress() {
        const headerDto: HeaderDto = {
            stepCart: false,
            showHeaderGuide: true,
            address: true,
            deliveryAndPayment: false
        };
        this.headerSharedService.updateValue(headerDto);

    }
}
