<section class="h-100">
  <div class="container">
    <div class="row d-flex justify-content-center">
      <div class="col-12 col-md-9 align-self-center">
        <div class="card">
          <div class="card-body p-4">

            <h5 class="mb-3"><a href="#!" class="text-body me-2">
              <fa-icon [icon]="faArrowLeft" class="me-2"></fa-icon>
              Pokračovat v nákupu</a></h5>
            <hr>

            <div class="d-flex justify-content-between align-items-center mb-4">
              <div>
                <p class="mb-1">Shopping cart</p>
                <p class="mb-0">Máte {{products.length}} {{getItem()}} v košíku</p>
              </div>
            </div>

            <div class="card mb-3" *ngFor=" let product of products">
              <div class="card-body">
                <div class="d-flex justify-content-center justify-content-sm-start">
                  <div class="d-flex flex-row align-items-center">

                    <div class="content text-start">
                      <img
                        routerLink="/product-detail/{{product.url}}"
                        [src]="asImage(product)"
                        class="img-fluid rounded-3 pointer ms-3 md-sm-0" alt="Shopping item">

                    </div>
                    <div class="ms-4 inner-content text-start text-sm-center">
                      <h5 class="pointer"
                          routerLink="/product-detail/{{product.url}}">{{product.name}}</h5>
                      <p class="small mb-2">{{product.isbn}}</p>
                    </div>

                  </div>

                  <div class="d-none d-md-flex w-100 align-items-center">
                    <div class="col pointer text-center w-50">
                      <fa-icon [icon]="faMinus" (click)="removeQuantity(product)"></fa-icon>
                      <a class="me-2 ms-2" (click)="addQuantity(product)">&nbsp;{{product.quantity}}</a>
                      <fa-icon [icon]="faPlus" (click)="addQuantity(product)"></fa-icon>
                    </div>
                    <div class="align-items-end pricing text-start text-sm-center text-md-end w-50">
                      <h5 class="mb-0">{{getPrice(product)}}&nbsp;Kč</h5>
                      <span *ngIf="product.quantity>1" class="d-inline">Cena za kus {{product.priceAmount}}</span>
                    </div>
                    <fa-icon class="pointer bg-gray ms-2" [icon]="faDelete" (click)="removeBasket(product)"></fa-icon>
                  </div>
                </div>

                <div class="row d-flex d-md-none">
                  <div class="col-4 col-sm-5 mt-2">
                    <div class="pointer text-start text-sm-center me-sm-5">
                      <fa-icon [icon]="faMinus" (click)="removeQuantity(product)"></fa-icon>
                      <a class="me-1" (click)="addQuantity(product)">&nbsp;{{product.quantity}}</a>
                      <fa-icon [icon]="faPlus" (click)="addQuantity(product)"></fa-icon>
                    </div>
                  </div>
                  <div class="col-8 col-sm-7 mt-2 text-center text-sm-start">
                    <h5 class="ms-1 d-inline">{{getPrice(product)}}&nbsp;Kč</h5>
                    <fa-icon class="pointer bg-gray d-inline ms-2 mb-1" [icon]="faDelete"
                             (click)="removeBasket(product)"></fa-icon>
                  </div>
                </div>
                <div class="d-flex d-md-none">
                  <div class="text-end text-sm-center me-1 me-sm-0 mt-1 w-100" *ngIf="product.quantity>1"> Cena za kus {{product.priceAmount}}</div>
                </div>
              </div>
            </div>

            <div class="card mb-3">
              <div class="card-body">
                <div>
                  <h5 class="ms-3">Shrnutí objednávky</h5>
                </div>
                <hr>
                <div class="d-flex justify-content-between px-4">
                  <p class="mb-1 text-start">Položky</p>
                  <h6 class="mb-1 text-end">{{totalPrice}}&nbsp;Kč</h6>
                </div>
                <div class="d-flex justify-content-between px-4">
                  <p class="mb-1 text-start">{{deliveryModel.deliveryType}}</p>
                  <h6 class="mb-1 text-end">{{deliveryModel.price}} Kč</h6>
                </div>
                <div class="d-flex justify-content-between px-4">
                  <p class="mb-1 text-start">{{paymentModel.paymentType}}</p>
                  <h6 class="mb-1 text-end">{{paymentModel.price}} Kč</h6>
                </div>
                <div class="d-flex justify-content-between px-4">
                  <p class="mb-1 text-start">Celkem</p>
                  <h6 class="mb-1 text-end">{{totalPrice + paymentModel.price + deliveryModel.price}}&nbsp;Kč</h6>
                </div>
                <div class="d-flex justify-content-end pt-3">
                  <button class="btn btn-primary" [routerLink]="['/delivery']" (click)="activePaymentStep()">
                    Pokračovat v objednávce
                  </button>
                </div>

              </div>
            </div>

          </div>
        </div>

      </div>
    </div>
  </div>
</section>
