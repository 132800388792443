import {Component} from '@angular/core';
import {ErrorDto} from "../common/abstract/base-service";
import {SliderEmittedVo} from "../ngx-slider-wrapper/ngx-slider-wrapper.component";
import {ProductSortEmittedVo} from "../enum-drop-down-form/enum-drop-down.component";
import {VariantCatalogEmittedVo} from "../variant-catalog-filter/variant-catalog-filter.component";

@Component({
  selector: 'app-product-list-home',
  templateUrl: './product-list-home.component.html',
  styleUrl: './product-list-home.component.scss'
})
export class ProductListHomeComponent {
  errorDto: ErrorDto = new ErrorDto();
  sliderEmittedVo: SliderEmittedVo = null
  productSortEmittedVo: ProductSortEmittedVo = null
  variantCatalogEmittedVo: VariantCatalogEmittedVo = null

  changeSliderMinMax($event: SliderEmittedVo) {
    this.sliderEmittedVo = $event;
  }

  changeProductSortValue($event: ProductSortEmittedVo) {
    this.productSortEmittedVo = $event;
  }

  changeCatalogEmittedValue($event: VariantCatalogEmittedVo) {
    this.variantCatalogEmittedVo = $event
  }
}

