import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {HeaderDto} from '../model/header/header-dto';

@Injectable({
    providedIn: 'root'
})
export class HeaderSharedService {

    private headerDtoBehaviorSubject = new BehaviorSubject<HeaderDto>(new HeaderDto());
    private headerDtoDefault: HeaderDto = {
        showHeaderGuide: false,
        address: false,
        deliveryAndPayment: false,
        stepCart: false
    };

    constructor() {
        try {
            const data: HeaderDto = JSON.parse(localStorage.getItem('headerGuide'));
            this.updateValue(data);
        } catch (e) {
            localStorage.removeItem('headerGuide');
            this.updateValue(this.headerDtoDefault);
        }
    }

    getHeaderGuideAsObservable(): Observable<HeaderDto> {
        return this.headerDtoBehaviorSubject.asObservable();
    }


    updateValue(headerDto: HeaderDto) {
        localStorage.removeItem('headerGuide');
        this.headerDtoBehaviorSubject.next(headerDto);
        localStorage.setItem('headerGuide', JSON.stringify(headerDto));
    }
}
