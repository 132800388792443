<div class="container pt-4">
  <h1 class="text-center d-block">Obchodní podmínky</h1>
  <span class="text-center d-block fw-bold">{{advertisementDto.eshopName}}</span>
  <span class="d-block ps-2 pt-3 fw-bold">Základní ustanovení</span>
  <ol class="list-group list-group-numbered pt-3">
    <li class="list-group-item ">Tyto obchodní podmínky upravují vztahy mezi kupujícím a prodávajícím v oblasti
      prodeje {{advertisementDto.sortiment}} a souvisejícího zboží mezi společností {{advertisementDto.eshopName}} (dále jen <span class="fw-bold">„Prodávající“</span>)
      a jejími
      zákazníky (dále jen <span class="fw-bold">„Kupující“</span>).
    </li>
    <li class="list-group-item ">Prodávajícím je společnost {{advertisementDto.eshopName}}, se sídlem {{advertisementDto.address}}
      která je registrovaným podnikatelským subjektem s IČO {{advertisementDto.ico}}. Kontaktní údaje na Prodávajícího jsou:
      <span class="d-block ps-4">email: <span class="fw-bold">{{advertisementDto.emailWho}}</span></span>
      <span class="d-block ps-4">tel. číslo: <span class="fw-bold">{{advertisementDto.phone}}</span></span>
    </li>
    <li class="list-group-item">Kupujícím se rozumí každý člověk, který mimo rámec své podnikatelské činnosti nebo mimo
      rámec samostatného výkonu svého povolání uzavírá smlouvu s Prodávajícím nebo s ním jinak jedná za účelem koupě
      zboží nabízeného Prodejcem.
    </li>
    <li class="list-group-item">Při zahájení právního jednání předává Kupující Prodávajícímu pouze své kontaktní údaje,
      nutné pro bezproblémové vyřízení objednávky, popřípadě údaje, které chce mít uvedeny na nákupních dokladech.
    </li>
    <li class="list-group-item">Právní vztahy Prodávajícího s Kupujícím výslovně neupravené těmito obchodními podmínkami
      se řídí příslušnými ustanoveními zák. č. 89/2012 Sb., (občanský zákoník), zák. č. 634/1992 Sb. o ochraně
      spotřebitele, jakož i právními předpisy souvisejícími.
    </li>
    <li class="list-group-item">Kupující si je vědom, že mu koupí produktů, jež jsou v obchodní nabídce Prodávajícího,
      nevznikají žádná práva na používání registrovaných značek, obchodních názvů, firemních log či patentů
      Prodávajícího nebo dalších firem, není-li v konkrétním případě zvláštní smlouvou sjednáno jinak.
    </li>
  </ol>


  <span class="d-block ps-2 pt-3 fw-bold">Bezpečnost a ochrana informací</span>
  <ol class="list-group list-group-numbered pt-3">
    <li class="list-group-item ">Uzavřením smlouvy Kupující potvrzuje, že osobní údaje, které uvedl jsou pravdivé a
      souhlasí s tím, aby poskytnuté údaje byly Prodávajícím zpracovány a uchovány v souladu se zákonem o ochraně
      osobních údajů a Obecným nařízením o ochraně osobních údajů (GDPR).
    </li>
    <li class="list-group-item ">Prodávající prohlašuje, že veškeré osobní údaje Kupujícího budou použity pouze k
      uskutečnění plnění smlouvy a nebudou jinak zveřejněny nebo poskytnuty třetí osobě; s výjimkou situace související
      s distribucí či platebním stykem týkajícího se objednaného zboží (sdělení jména a adresy dodání).
    </li>
    <li class="list-group-item">Více se o ochraně osobních údajů dočtete v dokumentu s názvem Jak chráníme Vaše osobní
      údaje, který je dostupný na internetových stránkách Prodejce.
    </li>
  </ol>

  <span class="d-block ps-2 pt-3 fw-bold">Objednání</span>
  <ol class="list-group list-group-numbered pt-3">
    <li class="list-group-item ">Prodávající nedisponuje tzv. kamenným obchodem, kde si může Kupující zboží osobně
      prohlédnout a vyzvednout. Pro účel nabídky zboží slouží internetový obchod, který je provozován na webové
      adrese {{advertisementDto.webEshopAddress}}, tudíž je k dispozici 24 hodin denně, 7 dní v týdnu.
    </li>
    <li class="list-group-item ">Kupující od Prodávajícího kupuje zboží za cenu platnou v okamžiku objednání. Kupní cena
      je vždy uvedena u konkrétního zboží v internetovém obchodě.
    </li>
    <li class="list-group-item">Náklady na poštovné a balné nese kupující dle způsobu, který zvolil při objednání zboží.
      Tyto náklady se řídí cenami uvedenými v článku <span class="fw-bold">"Dodací podmínky"</span>. těchto obchodních
      podmínek.
    </li>
  </ol>

  <span class="d-block ps-2 pt-3 fw-bold">Uzavření smlouvy</span>
  <ol class="list-group list-group-numbered pt-3">
    <li class="list-group-item ">K uzavření kupní smlouvy dochází na základě přijetí nabídky Prodávajícího Kupujícím
      prostřednictvím objednání zboží, které je uvedeno na internetových stránkách Prodejce. Objednávku nelze učinit
      telefonicky.
    </li>
    <li class="list-group-item">Každá smlouva uzavřená mezi Prodávajícím a Kupujícím může být uzavřena pouze v českém
      jazyku.
    </li>
    <li class="list-group-item">Přijetí nabídky s dodatkem nebo odchylkou není přijetím nabídky a nevyvolá právní
      účinky, a to i v případě, když dodatek nebo odchylka podstatně nemění podmínky nabídky.
    </li>
    <li class="list-group-item ">Převzetí nevyžádaného plnění ze strany Kupujícího neznamená přijetí nabídky. V případě
      přebírání nevyžádaného plnění je Kupující povinen o existenci tohoto plnění Prodávajícího informovat
    </li>
    <li class="list-group-item">Po dokončení objednávky bude Kupujícímu elektronickou cestou zasláno potvrzení
      objednávky (uzavření kupní smlouvy). Objednávka Kupujícího zůstává uložena u Prodávajícího.
    </li>
    <li class="list-group-item">Chyby vzniklé při zadávání dat před podáním objednávky může Kupující opravit pouze do
      odeslání objednávky. Po odeslání objednávky Prodávajícímu může Kupující Prodávajícího o těchto chybách informovat
      prostřednictvím jeho kontaktních údajů. Pro Prodávajícího jsou tyto změny právně závazné pouze v případě, že
      Kupujícímu písemně potvrdí, že je s nimi srozuměn.
    </li>
  </ol>

  <span class="d-block ps-2 fw-bold pt-3">Odstoupení od smlouvy</span>
  <ol class="list-group list-group-numbered pt-3">
    <li class="list-group-item">V případě, že Kupující odebral zboží jiným způsobem než osobně, tedy když mu bylo
      objednané zboží doručeno dopravní službou, je lhůta pro odstoupení od smlouvy 14 dní od doručení.
    </li>
    <li class="list-group-item">Naplní-li Kupující všechny náležitosti pro odstoupení od spotřebitelské smlouvy uzavřené
      distančním způsobem a rozhodne-li se pro odstoupení ve shora uvedené lhůtě, je nutno splnit následující podmínky:
      <ol class="list-group list-group-numbered pt-3">
        <li class="list-group-item ps-2">Odeslat dopis (nejlépe e-mailem na adresu <span class="fw-bold">{{advertisementDto.eshopEmail}}</span>) s textem:
          <p class="font-monospace ps-2 pt-3"> „Já …………. (jméno
          Kupujícího) jsem dne DD.MM.RRRR uzavřel smlouvu č. (číslo objednávky/faktury) se společností Dům zahrady a.s.
          V návaznosti na ustanovení zák. č. 89/2012 Sb. o spotřebitelských smlouvách uzavřených distančním způsobem
          tímto jednostranně odstupuji od výše uvedené smlouvy, požaduji vrácení uhrazené částky za zboží na účet číslo:
          …………………/……… a prohlašuji, že splňuji všechny zákonné požadavky pro toto odstoupení od smlouvy dle příslušných
          právních předpisů“.
          </p>
        </li>
        <li class="list-group-item">V případě písemného vyhotovení dopisu je Kupující povinen opatřit dopis příslušným
          datem a vlastnoručním podpisem.]
        </li>
        <li class="list-group-item">
          Zboží odesílané a doručené zpět na adresu Prodávajícího musí být nepoškozené, kompletní (včetně
          příslušenství, návodu atd.) a s kopií dokladu o koupi. V případě nedodržení některé z uvedených náležitostí má
          Prodávající právo odmítnout vrácené zboží nebo poměrně snížit částku, za kterou bude zboží přijato zpět.
        </li>
        <li class="list-group-item">
          Možnost odstoupit od smlouvy se nevztahuje na zboží upravené dle přání Kupujícího a jiné zboží, které nelze vrátit do původního stavu před koupí.
        </li>
      </ol>
    </li>
  </ol>

  <span class="d-block ps-2 pt-3 fw-bold">Reklamace zboží a práva z vadného plnění</span>
  <ol class="list-group list-group-numbered pt-3">
    <li class="list-group-item">
      Reklamační řád včetně práva z vadného plnění naleznete na <a href="/terms">reklamační řád</a>
    </li>
  </ol>

  <span class="d-block ps-2 pt-3 fw-bold">Informace o zboží</span>
  <ol class="list-group list-group-numbered pt-3">
    <li class="list-group-item">
      Informace o zboží nabízeném v internetovém obchodu Prodávajícího mají pouze ilustrační charakter a v detailech se mohou lišit od dodávaného zboží.
    </li>
    <li class="list-group-item">
      V internetovém obchodě jsou vždy aktuální a platné ceny nabízeného zboží. V ceně zboží není zahrnuto poštovné a balné.
    </li>
  </ol>

  <span class="d-block ps-2 pt-3 fw-bold">Platební podmínky</span>
  <ol class="list-group list-group-numbered pt-3">
    <li class="list-group-item">
      Objednané zboží je možné zaplatit:
      <span class="d-block ps-4 fw-bold">platbou pomocí převodu na účet Prodávajícího po dokončení objednávky.</span>
    </li>
    <li class="list-group-item">
      Zboží zůstává do úplného zaplacení majetkem Prodávajícího.
    </li>
  </ol>

  <span class="d-block ps-2 pt-3 fw-bold">Dodací podmínky</span>
  <ol class="list-group list-group-numbered pt-3">
    <li class="list-group-item">Zasílání dopravní službou: Objednané zboží bude odesláno poštovní dopravní službou.
      Cena dopravy se řídí dle ceníku dopravce aktuálního v den objednávky.</li>
    <li class="list-group-item">Při nákupu zboží nad<span class="fw-bold">{{advertisementDto.limitFreeShippingPrice}}</span> a doručení po ČR hradí náklady na dopravu zboží Prodávající.</li>
    <li class="list-group-item">Kupující je povinen při dodání zboží převzít s výjimkou uvedenou v čl.
      <span class="fw-bold">Dodací podmínky.</span> odst. 5 těchto obchodních podmínek. Nepřevezme-li kupující zboží při dodání, je Prodávající oprávněn od kupní smlouvy odstoupit.</li>
    <li class="list-group-item">Pokud je z důvodu na straně Kupujícího nutno zboží doručovat opakovaně nebo jiným způsobem, než bylo uvedeno v objednávce, je Kupující povinen uhradit náklady spojené s opakovaným doručováním zboží, jakož i náklady spojené s jiným způsobem doručení.</li>
    <li class="list-group-item">Při přebírání zboží od dopravce je Kupující povinen zkontrolovat neporušenost obalů zboží a případné závady oznámit dopravci. Zboží se zjevně poškozeným obalem není Kupující povinen od dopravce převzít.</li>
    <li class="list-group-item">Podpisem dodacího listu kupující stvrzuje, že zásilka zboží splňovala všechny podmínky a náležitosti a na případnou pozdější reklamaci ohledně porušení obalu zásilky nebude brán zřetel</li>
    <li class="list-group-item">Další práva a povinnosti při dopravě zboží mohou být upraveny v příslušných dodacích podmínkách dopravce</li>
  </ol>

  <span class="d-block ps-2 pt-3 fw-bold">Závěrečná ustanovení</span>
  <ol class="list-group list-group-numbered pt-3">
    <li class="list-group-item">Tyto obchodní podmínky tvoří nedílnou součást každé smlouvy uzavřené mezi Prodávajícím a Kupujícím s platností od {{advertisementDto.dueDate | date: 'dd.MM.yyyy'}} </li>
    <li class="list-group-item">Aktuální obchodní podmínky jsou k dispozici na internetových stránkách Prodávajícího.</li>
    <li class="list-group-item">Prodávající si vyhrazuje právo změnit tyto obchodní podmínky bez předchozího upozornění.</li>
  </ol>

</div>


