import {Directive, Output} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, Validator} from '@angular/forms';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[emailApp]',
    providers: [
        {provide: NG_VALIDATORS, useExisting: EmailDirectiveDirective, multi: true}
    ]
})
export class EmailDirectiveDirective implements Validator {

    @Output()
    isInvalidEmail = false;
    constructor() {
    }

    regex: RegExp = new RegExp('^[\\w!#$%&\'*+/=?`{|}~^-]+(?:\\.[\\w!#$%&\'*+/=?`{|}~^-]+)*@(?:[a-zA-Z0-9-]+\\.)+[a-zA-Z]{2,6}$');

    validate(control: AbstractControl): { [key: string]: any } | null {
        return (this.regex.test(control.value)) ? null : {isInvalidEmail: true};
    }
}
