<div class="card">
    <div class="container px-4 py-5 mx-auto">
        <h4 class="heading">Způsob doručení</h4>
        <div class="payment-option-main">
            <h3>
                <label class="payment-option-label-main">
                    <input id="post-office" type="radio" name="post-office-delivery"
                           data-toggle="collapse" data-target="#post-office-delivery"
                           [checked]="true" readonly>
                    <span class="payment-option-title">Česká&nbsp;pošta - Balík&nbsp;na&nbsp;poštu&nbsp;&nbsp;<img
                            height="24px" [src]="getPostOfficeImage()"></span>
                    <span class="payment-option-title">{{postOfficePrice}}Kč</span>
                </label>
            </h3>
            <div class="payment-body">
                <div id="post-office-delivery" class="collapse">
                    Doručení balíku na vybranou poštu následující pracovní den po podání.
                </div>
            </div>
        </div>
        <h4 class="heading">Zvolte způsob platby</h4>
        <div class="payment-option-main">
            <h3>
                <label class="payment-option-label-main">
                    <input id="bank-transfer" type="radio" name="payment_type_transfer"
                           data-toggle="collapse" data-target="#bank-transfer-info"
                           [checked]="isTransferSelectedDefault"
                           (click)="applyTransfer()" readonly>
                    <span class="payment-option-title">Bankovní&nbsp;převod&nbsp;&nbsp;<i class="fa fa-university"
                                                                                          aria-hidden="true"></i></span>
                    <span class="payment-option-title">{{getBankTransferPrice()}}</span>
                </label>
            </h3>
            <div class="payment-body">
                <div id="bank-transfer-info" class="collapse" [hidden]="!isTransferSelectedDefault">
                    Po odeslání objednávky vám obratem zašleme údaje pro realizování platby (číslo účtu,
                    variabilní symbol a částku).
                    <br><br><span style="color: #ff0000;">Termín dodání objednávky se může vzhledem na zvolenou formu platby prodloužit. Objednávka se začne zpracovávat až po přijetí platby.</span>
                </div>
            </div>
        </div>

        <div class="payment-option-main">
            <h3>
                <label class="payment-option-label-main">
                    <input id="bank-proforma" type="radio" name="payment_type_proforma"
                           data-toggle="collapse" data-target="#bank-proforma-info"
                           [checked]="isProformaSelected"
                           (click)="applyProforma()"
                           readonly>
                    <span class="payment-option-title">Dobírka&nbsp;&nbsp;<i class="fa fa-file-text-o"
                                                                             aria-hidden="true"></i></span>
                    <span class="payment-option-title">{{getProformaPrice()}}</span>
                </label>
            </h3>

            <div class="payment-body">
                <div id="bank-proforma-info" class="collapse" [hidden]="!isProformaSelected">
                    <span style="color: #ff0000;">Z bezpečnostních důvodů vám doporučujeme využít platbu kartou předem, aby mohlo převzetí vašeho balíčku proběhnout co nejrychleji.</span>
                </div>
            </div>
        </div>

        <button type="submit" class="btn btn-info ml-auto" [routerLink]="['/delivery-address']"
                (click)="activeDeliveryAddress()">&nbsp;Pokračovat
        </button>

    </div>
</div>
