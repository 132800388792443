import {Component, EventEmitter, Input, Output} from '@angular/core';
import {VariantCatalog} from '../model/variant-catalog/variant-catalog';

@Component({
  selector: 'app-variant-catalog-filter-item',
  templateUrl: './variant-catalog-filter-item.component.html'
})
export class VariantCatalogFilterItemComponent {

  @Input() variantCatalogDataItem: VariantCatalog;

  @Output() filterChange = new EventEmitter<FilterChangeEventVo>();

  fireFilterChangeEvent($event: Event, id: number) {
    console.log($event);
    let target = <HTMLInputElement>$event.target;
    const isChecked = target.checked;

    let filterChangeEventVo = new FilterChangeEventVo(this.variantCatalogDataItem.paramName, id, isChecked);
    this.filterChange.emit(filterChangeEventVo);
    console.log('emitted' + JSON.stringify(filterChangeEventVo));
  }
}

export class FilterChangeEventVo {
  get filterParamName(): string {
    return this._filterParamName;
  }

  get filterItemId(): number {
    return this._filterItemId;
  }

  get checked(): boolean {
    return this._checked;
  }

  constructor(private _filterParamName: string, private _filterItemId: number, private _checked: boolean) {
  }
}
