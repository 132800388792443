<!-- Wrapper container -->
<div class="container py-4 border border-1 rounded rounded-3 col-6">
    <h2 class="h1-responsive font-weight-bold text-center my-4">Napište nám</h2>
    <!-- Bootstrap 5 starter form -->
    <form id="contactForm" #contactUsForm="ngForm" novalidate (ngSubmit)="contactUsForm.form.valid && contactUs() ">

      <div class="form-floating mb-3">
        <input type="text" name="name" class="form-control" id="contactName" placeholder="Jméno"
               [(ngModel)]="writeUs.name" #name="ngModel">
        <label for="contactName">Jméno</label>
      </div>

      <!-- Email address input -->
      <div class="form-floating mb-3">
        <input type="text" name="email" class="form-control" placeholder="Email *" id="contact-us-email"
               [(ngModel)]="writeUs.emailFrom" #email="ngModel" required emailApp/>
        <label for="contact-us-email">Email *</label>
      </div>

      <div *ngIf="contactUsForm.submitted && email.errors && (email.invalid || email.touched)">
        <div class="alert alert-danger" *ngIf="email.errors.required" type='danger'>
          Email je povinný.
        </div>
        <div class="alert alert-danger" *ngIf="email.errors.isInvalidEmail && writeUs.emailFrom" type='danger'>
          Promiň, ale vypadá to na nevalidní emailovou adresu
        </div>
      </div>

      <div class="form-floating mb-3">
        <input type="text" name="phone" class="form-control" placeholder="Telefoní číslo" id="contact-us-phone"
               [(ngModel)]="writeUs.phoneNumber" #phone="ngModel"/>
        <label for="contact-us-phone">Telefoní číslo</label>
      </div>


      <div *ngIf="send" [className]="clazz">
        {{notificationMessage}}
      </div>

      <ngx-spinner bdColor="rgba(51,51,51,0.8)"
                   size="medium"
                   color="#fff"
                   type="ball-spin-clockwise"
                   fullScreen=true>
      </ngx-spinner>

      <div class="form-floating mb-3">
      <textarea name="body" class="form-control" [placeholder]="'Co chcete říct...'" id="contact-us-message"
                required minlength="14" maxlength="254"
                style="width: 100%; height: 150px; padding: 15px"
                [(ngModel)]="writeUs.body" #body="ngModel"></textarea>
        <label for="contact-us-message">Co chcete říci...</label>
        <div *ngIf="contactUsForm.submitted && body.errors && (body.invalid || body.touched)"
             class="alert alert-danger">
          <div *ngIf="body.errors.required">
            Zpráva není vyplněna!
          </div>
          <div *ngIf="body.errors.minlength">
            Promiň, ale na dotaz to je to příliš krátké
          </div>
        </div>
      </div>
      <!-- Form submit button -->
      <div class="d-grid">
        <button class="btn btn-primary btn-lg" type="submit">Submit</button>
      </div>
    </form>
</div>
