import {Injectable} from '@angular/core';
import {BaseService, ErrorDto} from '../common/abstract/base-service';
import {HttpClient} from '@angular/common/http';
import {catchError} from 'rxjs/operators';
import {Order} from '../model/order/order';
import {OrderCreate} from '../model/order/order-create';
import {AppConstants} from '../app-constants';

@Injectable({
  providedIn: 'root'
})
export class OrderService extends BaseService {

  private createOrderEndpoint = AppConstants.baseUrl + '/api/orders';

  constructor(private httpClient: HttpClient) {
    super();
  }

  public createOrder(errorDto: ErrorDto, order: Order) {
    const body = JSON.stringify(new OrderCreate(order));
    return this.httpClient.post(this.createOrderEndpoint, body,
      {
        headers: AppConstants.contentTypeAndAcceptJson
      })
      .pipe(catchError((err) => this.handleError(err, errorDto)));
  }
}
