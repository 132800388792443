import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {PaymentType} from '../model/delivery/payment-type';

@Injectable({
    providedIn: 'root'
})
export class PaymentSharedService {

    private subjectPaymentType = new BehaviorSubject<PaymentType>(PaymentType.BANK_TRANSFER);

    constructor() {
        this.reload();
    }

    getPaymentObservable(): Observable<PaymentType> {
        return this.subjectPaymentType.asObservable();
    }

    reload() {
        const data = localStorage.getItem('paymentType');
        if (data === PaymentType.PROFORMA.valueOf()) {
            localStorage.removeItem('paymentType');
            localStorage.setItem('paymentType', PaymentType.PROFORMA);
            this.subjectPaymentType.next(PaymentType.PROFORMA);
        } else {
            localStorage.removeItem('paymentType');
            localStorage.setItem('paymentType', PaymentType.BANK_TRANSFER);
            this.subjectPaymentType.next(PaymentType.BANK_TRANSFER);
        }
    }

    changeValue(paymentType: PaymentType) {
        localStorage.removeItem('paymentType');
        this.subjectPaymentType.next(paymentType);
        localStorage.setItem('paymentType', paymentType);
    }
}
