import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {VariantCatalogService} from "../service/variant-catalog.service";
import {takeUntil} from "rxjs/operators";
import {NgxSpinnerService} from "ngx-spinner";
import {ErrorDto} from "../common/abstract/base-service";
import {Subject} from "rxjs";
import {VariantCatalog} from "../model/variant-catalog/variant-catalog";
import {ActivatedRoute, Router} from "@angular/router";
import {FilterChangeEventVo} from "../variant-catalog-filter-item/variant-catalog-filter-item.component";

@Component({
  selector: 'app-variant-catalog-filter',
  templateUrl: './variant-catalog-filter.component.html',
})
export class VariantCatalogFilterComponent implements OnInit, OnDestroy {

  destroy$: Subject<boolean> = new Subject<boolean>();
  errorDto: ErrorDto = new ErrorDto();

  private readonly _name = 'variant-catalog';
  protected variantCatalog: VariantCatalog[] = [];

  @Output() variantCatalogEmittedValue = new EventEmitter<VariantCatalogEmittedVo>();

  /**
   * Map of checkbox states queryParamName -> EnabledFilterItem
   * @private
   */
  private actualCheckBoxStates = new Map<string, Array<EnabledFilterItem>>();


  private putOrUpdate($event: FilterChangeEventVo) {
    let key = $event.filterParamName;

    let found = this.actualCheckBoxStates.get(key)
    let checked = $event.checked;
    let enabledFilterItem = new EnabledFilterItem($event.filterItemId, checked);
    if (!checked) {
      this.cleanNotCheckedFromStates(key, enabledFilterItem)
      return;
    }

    if (found == null) {
      this.actualCheckBoxStates.set(key, [enabledFilterItem])
    } else {
      found.push(enabledFilterItem)
    }
  }

  private cleanNotCheckedFromStates(inKey: string, enabledFilterItem: EnabledFilterItem) {
    let found = this.actualCheckBoxStates.get(inKey)
    if (found == null) {
      return;
    }
    let filteredOutChanged = found.filter(item => item.filterId !== enabledFilterItem.filterId)
    this.actualCheckBoxStates.set(inKey, filteredOutChanged)
  }


  constructor(private variantCatalogService: VariantCatalogService,
              private spinner: NgxSpinnerService,
              private _route: ActivatedRoute,
              private _router: Router) {
  }

  ngOnInit(): void {
    this.loadVariantCatalog();
  }

  navigateToQueryParams() {
    var obj = {
      param1: 'something',
      param2: 'somethingelse',
      param3: 'another'
    }

    // this._router.navigate([], {
    //   queryParams: obj,
    //   queryParamsHandling: '',
    // });

    //console.log(this._router.url)

    let params = new Array<number>();
    this.actualCheckBoxStates.forEach((value, key) => {
      let joined = value.map(item => item.filterId);
      params = params.concat(joined)
    });
    this.variantCatalogEmittedValue.emit({selectedVariants: params});

  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private loadVariantCatalog() {
    this.spinner.show(this._name).then(r => r);
    this.variantCatalogService.listAll(this.errorDto)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: catalogVariants => {
          this.variantCatalog = catalogVariants.data;
        },
        error: () => {
          this.spinner.hide('Variant Catalog').then(
            () => {
              this.variantCatalog = [];
            });
        }
      });
  }

  processFilterChangeEvent($event: FilterChangeEventVo) {
    this.putOrUpdate($event)
    this.navigateToQueryParams();
    console.log($event);
  }
}

export class EnabledFilterItem {

  get filterId(): number {
    return this._filterId;
  }

  get enabled(): boolean {
    return this._enabled;
  }

  constructor(private _filterId: number, private _enabled: boolean) {
  }
}

export class VariantCatalogEmittedVo {
  selectedVariants: Array<number> = [];
}
