import {Injectable} from '@angular/core';
import {BaseService, ErrorDto} from '../common/abstract/base-service';
import {HttpClient} from '@angular/common/http';
import {AppConstants} from '../app-constants';
import {catchError} from 'rxjs/operators';
import {AdvertisementResponse} from '../model/advertisement/advertisement-response';

@Injectable({
  providedIn: 'root'
})
export class AdvertisementService extends BaseService {

  constructor(private httpClient: HttpClient) {
    super();
  }

  private advertisementEndpoint = AppConstants.baseUrl + '/api/config/advertisement';

  public findAdvertisement(errorDto: ErrorDto) {
    return this.httpClient.get<AdvertisementResponse>(this.advertisementEndpoint, {headers: AppConstants.acceptJson})
      .pipe(
        catchError((err) => this.handleError(err, errorDto)));
  }
}
