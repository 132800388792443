import {Component, OnDestroy, OnInit} from '@angular/core';
import {Order} from '../model/order/order';
import {BasketSharedService} from '../service/basket-shared.service';
import {OrderItem} from '../model/order/order-item';
import {Product} from '../model/product/product';
import {OrderService} from '../service/order.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {Address} from '../model/address/address';
import {ErrorDto} from '../common/abstract/base-service';


@Component({
    selector: 'app-address',
    templateUrl: './address.component.html',
    styleUrls: ['./address.component.scss']
})
export class AddressComponent implements OnInit, OnDestroy {
    order = new Order();
    totalPrice: number;
    shippingAddress = new Address();
    billingAddress = new Address();
    items = Array<OrderItem>();
    errorMessage: string;
    products = Array<Product>();
    destroy$: Subject<boolean> = new Subject<boolean>();
    errorDto = new ErrorDto();

    constructor(protected basketSharedService: BasketSharedService,
                protected orderService: OrderService) {
        this.init();
    }

    init() {
        this.totalPrice = 0;
        this.order = new Order();
        this.shippingAddress = new Address();
        this.billingAddress = new Address();
        this.shippingAddress.country = 'Czech Republic';
        this.billingAddress.country = 'Czech Republic';
        this.order.billingAsShipping = true;
    }

    ngOnInit(): void {
        this.basketSharedService.getBasket().subscribe({
            next: data => {
                this.products = data;
                this.items = this.mapToOrderItem(data);
                this.products.map( product => this.totalPrice += product.priceAmount * product.quantity);
            },
            error: err => {
                this.items = Array<OrderItem>();
                console.log(err);
            }
        });
    }

    private mapToOrderItem(product: Product[]) {
        return product.map(data => new OrderItem(data.url, data.quantity));
    }

    createOrder() {
        this.order.items = this.items;
        this.order.shippingAddress = this.shippingAddress;
        this.order.billingAddress = this.order.billingAsShipping ? this.shippingAddress : this.billingAddress;
        this.orderService.createOrder(this.errorDto,this.order)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: response => {
                    this.errorMessage = '';
                    console.log(response);
                    console.log('Order created');
                },
                error: err => {
                    this.errorMessage = 'Order was not created';
                    console.error(this.errorMessage, err);
                }
            });
    }

    asImage(product: Product) {
        return 'data:image/jpeg;base64,' + product.file.bytes;
    }

    getQuantity(product: Product) {
        return 'počet: ' + product.quantity;
    }

    getPrice(product: Product) {
        return product.priceAmount * product.quantity;
    }

    ngOnDestroy(): void {
        this.init();
        this.destroy$.next(true);
    }
}
