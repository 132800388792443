import {OrderItem} from './order-item';
import {Address} from '../address/address';

enum PaymentType {
  CREDIT_CARD
}

export class Order {
  email: string;
  paymentType: PaymentType = PaymentType.CREDIT_CARD;
  items: Array<OrderItem>;
  billingAddress: Address;
  shippingAddress: Address;
  billingAsShipping: boolean;
}
