import {Component, OnDestroy, OnInit} from '@angular/core';
import {BasketSharedService} from '../service/basket-shared.service';
import {Product} from '../model/product/product';
import {HeaderDto} from '../model/header/header-dto';
import {HeaderSharedService} from '../service/header-shared.service';
import {AppConstants} from '../app-constants';
import {debounceTime, takeUntil} from 'rxjs/operators';
import {ProductService} from '../service/product.service';
import {Subject} from 'rxjs';
import {ProductFile} from '../model/product/product-file';
import {animate, query, stagger, state, style, transition, trigger} from '@angular/animations';
import {Router} from '@angular/router';
import {Category} from '../model/category/category';
import {CategoryService} from '../service/category.service';
import {ErrorDto} from '../common/abstract/base-service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [
    trigger('filterAnimation', [
      transition(':enter, * => 0, * => -1', []),
      transition(':increment', [
        query(':enter', [
          style({opacity: 0, width: 0}),
          stagger(50, [
            animate('300ms ease-out', style({opacity: 1, width: '*'})),
          ]),
        ], {optional: true})
      ]),
      transition(':decrement', [
        query(':leave', [
          stagger(50, [
            animate('300ms ease-out', style({opacity: 0, width: 0})),
          ]),
        ])
      ]),
    ]),
    trigger('hideShowAnimator', [
      state('true', style({opacity: 1, display: 'block'})),
      state('false', style({opacity: 0, display: 'none'})),
      transition('0 => 1', animate('.9s')),
      transition('1 => 0', animate('1.9s'))
    ]),
  ],
})
export class HeaderComponent implements OnInit, OnDestroy {
  productsInBasket: Array<Product>;
  headerDto = new HeaderDto();
  faPhone = AppConstants.iconPhone;
  faEnvelope = AppConstants.iconEnvelope;
  faShield = AppConstants.iconPolicy;
  faShipping = AppConstants.iconShipping;
  faSearch = AppConstants.iconSearch;
  faBasket = AppConstants.iconBasket;
  faUser = AppConstants.iconUser;

  searchByName: string;
  destroy$: Subject<boolean> = new Subject<boolean>();
  products: Array<Product>;
  productTotal = -1;
  categories: Array<Category>;
  showEmptyCart = false;
  errorDto = new ErrorDto();

  constructor(private basketService: BasketSharedService,
              private productService: ProductService,
              private categoryService: CategoryService,
              private headerSharedService: HeaderSharedService,
              private route: Router) {
  }

  searchBy(byName?: string) {
    if (!!byName) {
      this.loadProductsByName(byName);
    } else {
      this.products = [];
    }
  }

  getImage(file: ProductFile): string {
    if (!!file.bytes && !!file.type) {
      return 'data:' + file.type + ';base64,' + file.bytes;
    }
    return AppConstants.emptyDefaultImage;
  }

  loadProductsByName(byName ?: string) {
    console.log('Call filter function next');
    this.productService.filterProducts(this.errorDto, byName)
      .pipe(
        debounceTime(500),
        takeUntil(this.destroy$)
      )
      .subscribe({
        next: response => {
          console.log('Call filter function next');
          this.products = response.data;
          this.productTotal = this.products.length;
        },
        error: err => {
          this.products = [];
          this.productTotal = -1;
          console.error('Load products by name error', err);
        }
      });
  }

  loadCategories() {
    this.categoryService.findCategories(this.errorDto)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: response => {
          this.categories = response.data;
        },
        error: err => {
          this.categories = [];
        }
      });
  }

  activeCartStep() {
    const headerDto = new HeaderDto();
    headerDto.stepCart = true;
    headerDto.showHeaderGuide = true;
    headerDto.address = false;
    headerDto.deliveryAndPayment = false;
    this.headerSharedService.updateValue(headerDto);
    this.showEmptyCart = this.productsInBasket.length <= 0;
    console.log(this.showEmptyCart);
  }

  removeSearch() {
    this.products = [];
    this.productTotal = -1;
    this.searchByName = '';
  }

  productDetail(product: Product) {
    this.route.navigate(['product-detail/', product.categoryUrl, product.url])
      .then(r => this.removeSearch());
  }

  ngOnInit(): void {
    this.products = [];
    this.productTotal = -1;
    this.loadCategories();
    this.basketService.getBasket()
      .subscribe(msg => this.productsInBasket = msg);

    this.headerSharedService.getHeaderGuideAsObservable()
      .subscribe(data => {
        console.log(data);
        if (this.headerDto == null) {
          const headerDto = new HeaderDto();
          headerDto.stepCart = true;
          headerDto.showHeaderGuide = true;
          headerDto.address = false;
          headerDto.deliveryAndPayment = false;
          this.headerDto = headerDto;
        } else {
          this.headerDto = data;
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
