<div class="row">
  <ng-container *ngFor="let product of products | paginate: { itemsPerPage: this.productPagination.pageSize,
  currentPage: this.productPagination.pageNumber, totalItems: this.productPagination.totalElements }">
    <div class="col-3">
      <div class="product p-1 mb-5">

        <img (click)="productDetail(product)" [src]="getImage(product.file)" alt="{{product.name}}"
             class="img-fluid"/>

        <div class="card-body p-1">
          <h5 class="card-title">{{ product.name }}</h5>
          <div class="text-center mt-1">{{ product.priceAmount }}&nbsp;Kč</div>
        </div>

        <button (click)="addToCart(product)" class="btn btn-primary" type="button">
          <fa-icon [icon]="faShoppingBasket"></fa-icon>&nbsp;Koupit
        </button>

      </div>
    </div>
  </ng-container>
  <pagination-controls (pageChange)="reloadPage($event)" autoHide="false" maxSize="10"
                       responsive="true"></pagination-controls>
</div>
