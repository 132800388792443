<div class="container mt-5 mb-5">
  <div class="row d-flex justify-content-center">
    <div class="mb-3">
      <div
        class="alert alert-danger"
        role="alert"
        *ngIf="errorDto.httpStatus">
        {{ errorDto.httpStatusMessage }}<br>
        {{ errorDto.errorMessage }}
      </div>
    </div>
    <ngx-spinner bdColor="rgba(51,51,51,0.8)"
                 size="large"
                 color="#fff"
                 type="ball-spin-clockwise">
    </ngx-spinner>

    <div class="col-md-10">
      <div class="card">
        <div class="row">
          <div class="col-md-6">
            <div class="images p-3">
              <div class="text-center p-4">
                <img id="main-image" class="img-fluid" [src]="getImage(productFile)" width="300" /> </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="product p-4">
              <div class="d-flex justify-content-between align-items-center">
                <div class="d-flex align-items-center pointer"  [routerLink]="['/product-detail']">
                <fa-icon [icon]="faArrowLeft"></fa-icon><span class="ms-1">Back</span>
              </div>
              </div>
              <div class="mt-4 mb-3">
                <h5 class="text-uppercase">{{product.name}}</h5>
                <div class="price d-flex flex-row align-items-center"> <span class="act-price">{{product.priceAmount}}&nbsp;Kč</span>
                </div>
              </div>
              <p class="about" [innerHTML]="product.description"></p>
              <div class="cart align-items-center"> <button type="button" class="btn btn-primary" (click)="addToCart(product)">
                <fa-icon [icon]="faShoppingBasket"></fa-icon>&nbsp;Koupit
              </button></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
