<div class="row">
  <div class="mb-3">
    <div
      class="alert alert-danger"
      role="alert"
      *ngIf="errorDto.httpStatus">
      {{ errorDto.httpStatusMessage }}<br>
      {{ errorDto.errorMessage }}
    </div>
  </div>
  <ngx-spinner bdColor="rgba(51,51,51,0.8)"
               size="large"
               color="#fff"
               type="ball-spin-clockwise">
  </ngx-spinner>
</div>

<div class="row">
  <div class="col-3">
    <app-ngx-slider-wrapper (emittedValue)="changeSliderMinMax($event)"></app-ngx-slider-wrapper>
    <app-enum-drop-down (sortEmittedValue)="changeProductSortValue($event)"></app-enum-drop-down>
    <app-variant-catalog-filter
      (variantCatalogEmittedValue)="changeCatalogEmittedValue($event)"></app-variant-catalog-filter>
  </div>

  <div class="col-9">
    <app-product-list [errorDto]="errorDto"
                      [sliderEmittedVo]="sliderEmittedVo"
                      [productSortEmittedVo]="productSortEmittedVo"
                      [variantCatalogEmittedVo]="variantCatalogEmittedVo"></app-product-list>
  </div>
</div>
