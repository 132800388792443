import {Component, OnDestroy, OnInit} from '@angular/core';
import {takeUntil} from 'rxjs/operators';
import {Advertisement} from '../model/advertisement/advertisement';
import {Subject} from 'rxjs';
import {AdvertisementService} from '../service/advertisement.service';
import {ErrorDto} from '../common/abstract/base-service';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit, OnDestroy {
  advertisementDto: Advertisement = new Advertisement();
  destroy$: Subject<boolean> = new Subject<boolean>();
  errorDto: ErrorDto = new ErrorDto();
  constructor(private advertisementService: AdvertisementService) {
  }

  loadAdvertisement() {
    console.log('Reloading advertisement');
    this.advertisementService.findAdvertisement(this.errorDto)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: response => {
          this.advertisementDto = response.data;
        },
        error: err => {
          this.advertisementDto = new Advertisement();
        }
      });
  }

  ngOnInit(): void {
    this.loadAdvertisement();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
