import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ProductListComponent} from './product-list/product-list.component';
import {ContactComponent} from './contact/contact.component';
import {CartComponent} from './cart/cart.component';
import {AddressComponent} from './address/address.component';
import {DeliveryComponent} from './delivery/delivery.component';
import {ProductDetailComponent} from './product-detail/product-detail.component';
import {AdvertisingComponent} from './advertising/advertising.component';
import {TermsComponent} from './terms/terms.component';
import {ProductListHomeComponent} from "./product-list-home/product-list-home.component";

const routes: Routes = [
 // {path: '', pathMatch: 'full', redirectTo: 'products'},
  {path: 'products', component: ProductListHomeComponent},
  {path: 'products/:categoryUrl', component: ProductListHomeComponent},
  {path: 'product-detail/:productUrl', component: ProductDetailComponent},

  {path: 'cart', component: CartComponent},

  {path: 'contact', component: ContactComponent},
  {path: 'delivery-address', component: AddressComponent},
  {path: 'delivery', component: DeliveryComponent},
  {path: 'advertising', component: AdvertisingComponent},
  {path: 'terms', component: TermsComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
