import { Component, OnInit } from '@angular/core';
import {AppConstants} from '../app-constants';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  faPhone = AppConstants.iconPhone;
  faEnvelope = AppConstants.iconEnvelope;
  faHome = AppConstants.iconHome;
  constructor() { }

  ngOnInit(): void {
  }

}
