import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {Product} from '../model/product/product';
import {ProductService} from '../service/product.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {ProductFile} from '../model/product/product-file';
import {ActivatedRoute, Router} from '@angular/router';
import {BasketSharedService} from '../service/basket-shared.service';
import {faShoppingCart} from '@fortawesome/free-solid-svg-icons/faShoppingCart';
import {AppConstants} from '../app-constants';
import {ErrorDto} from '../common/abstract/base-service';
import {NgxSpinnerService} from 'ngx-spinner';
import {SliderEmittedVo} from "../ngx-slider-wrapper/ngx-slider-wrapper.component";
import {ProductSortEmittedVo} from "../enum-drop-down-form/enum-drop-down.component";
import {VariantCatalogEmittedVo} from "../variant-catalog-filter/variant-catalog-filter.component";
import {PageResponse} from "../model/pagination/page-response";

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss']
})
export class ProductListComponent implements OnInit, OnDestroy, OnChanges {
  selectedCategoryUrl: string;
  products: Array<Product> = [];
  destroy$: Subject<boolean> = new Subject<boolean>();
  productsInBasket: Array<Product>;
  faShoppingBasket = faShoppingCart;
  @Input() errorDto: ErrorDto = null;
  @Input() sliderEmittedVo: SliderEmittedVo = null;
  @Input() productSortEmittedVo: ProductSortEmittedVo = null;
  @Input() variantCatalogEmittedVo: VariantCatalogEmittedVo = null;
  productPagination: ProductPaginationVo = null
  // prevents bug during loading
  private loaded = false;
  private readonly _totalPages = null;
  private readonly _totalElements = null;
  private readonly _defaultPageSize = 10;
  private readonly _defaultPageNumber = 0;

  constructor(private productService: ProductService,
              private basketService: BasketSharedService,
              private actRoute: ActivatedRoute,
              private route: Router,
              private spinner: NgxSpinnerService) {
  }

  ngOnInit(): void {
    this.productPagination = this.createDefaultPagination()
    this.actRoute.paramMap.subscribe(params => {
      this.selectedCategoryUrl = params.get('categoryUrl');
      this.loadProducts(this.selectedCategoryUrl, undefined, undefined, this.variantCatalogEmittedVo);
    });
    this.basketService.getBasket().pipe(takeUntil(this.destroy$)).subscribe(msg => this.productsInBasket = msg);
    this.loaded = true;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.loaded) {
      this.loadProducts(this.selectedCategoryUrl, this.sliderEmittedVo, this.productSortEmittedVo, this.variantCatalogEmittedVo);
    }
  }

  public getImage(file: ProductFile): string {
    if (!!file.bytes && !!file.type) {
      return 'data:' + file.type + ';base64,' + file.bytes;
    }
    return AppConstants.emptyDefaultImage;
  }

  loadProducts(byCategoryUrl?: string,
               emittedVo?: SliderEmittedVo,
               sortEmittedVo?: ProductSortEmittedVo,
               variantCatalogEmittedVo?: VariantCatalogEmittedVo,
  ) {
    this.spinner.show().then(r => r);
    this.productService.filterProducts(this.errorDto, null, byCategoryUrl,
      emittedVo?.minValue,
      emittedVo?.maxValue,
      sortEmittedVo?.sortType,
      variantCatalogEmittedVo?.selectedVariants,
      this.productPagination
    )
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: response => {
          this.spinner.hide().then(
            () => {
              this.products = response.data
              this.productPagination = this.createPaginationVo(response.meta.page)
            }
          );
        },
        error: () => {
          this.spinner.hide().then(r => r);
        }
      });
  }

  createPaginationVo(page: PageResponse): ProductPaginationVo {
    return new ProductPaginationVo(page.totalPages, page.totalElements, page.pageSize, page.pageNumber)
  }

  addToCart(product: Product) {
    this.basketService.addToBasket(product);
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  productDetail(product: Product) {
    this.route.navigate(['product-detail/', product.url])
      .then(r => r);
  }

  reloadPage(page: number) {
    this.productPagination.pageNumber = page
    if (this.loaded) {
      this.loadProducts(this.selectedCategoryUrl, this.sliderEmittedVo, this.productSortEmittedVo, this.variantCatalogEmittedVo);
    }
  }

  private createDefaultPagination() {
    return new ProductPaginationVo(this._totalPages, this._totalElements, this._defaultPageSize, this._defaultPageNumber)
  }
}


export class ProductPaginationVo {
  totalPages?: number
  totalElements?: number
  pageSize?: number
  pageNumber?: number

  constructor(totalPages: number, totalElements: number, pageSize: number, pageNumber: number) {
    this.totalPages = totalPages;
    this.totalElements = totalElements;
    this.pageSize = pageSize;
    this.pageNumber = pageNumber;
  }
}
