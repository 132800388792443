import {Component, OnDestroy, OnInit} from '@angular/core';
import {AdvertisementService} from '../service/advertisement.service';
import {Advertisement} from '../model/advertisement/advertisement';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {ErrorDto} from '../common/abstract/base-service';

@Component({
  selector: 'app-advertising',
  templateUrl: './advertising.component.html',
  styleUrls: ['./advertising.component.scss']
})
export class AdvertisingComponent implements OnInit, OnDestroy {
  advertisementDto: Advertisement = new Advertisement();
  destroy$: Subject<boolean> = new Subject<boolean>();
  errorDto:ErrorDto = new ErrorDto();
  constructor(private advertisementService: AdvertisementService) {
  }

  ngOnInit(): void {
    this.loadAdvertisement();
  }

  loadAdvertisement() {
    console.log("Reloading advertisement");
    this.advertisementService.findAdvertisement(this.errorDto)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: response => {
          this.advertisementDto = response.data;
        },
        error: err => {
          this.advertisementDto = new Advertisement();
          console.error('Unable to load config from advertisement', err);
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
