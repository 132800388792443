<div class="superNav border-bottom">
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-6 text-center text-md-start small">
        <span class="d-none d-md-inline-block me-3"><fa-icon class="icon-blue"
                                                      [icon]="faEnvelope"></fa-icon>&nbsp;komiksarium&#64;gmail.com</span>
        <span class="me-3"><fa-icon class="icon-blue" [icon]="faPhone"></fa-icon>&nbsp;725&nbsp;406&nbsp;961</span>
      </div>
      <div class="col-12 col-md-6 d-none d-md-block text-end small">
        <span class="me-3"><fa-icon class="icon-blue" [icon]="faShipping"></fa-icon><a
          href="#">&nbsp;Shipping</a></span>
        <span class="me-3"><fa-icon class="icon-blue" [icon]="faShield"></fa-icon><a href="#">&nbsp;Obecné</a></span>
      </div>
    </div>
  </div>
</div>

<nav class="navbar navbar-expand-lg bg-white sticky-md-top shadow-sm">
  <div class="container">
    <a class="navbar-brand"><img class="img-logo" src="/assets/images/logo.png" alt="logo"> COMIC-ESHOP</a>
    <button class="navbar-toggler" type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarNavDropdown">

      <ul class="navbar-nav">
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button"
             data-bs-toggle="dropdown" aria-expanded="false">
            Produkty
          </a>
          <ul *ngIf="categories?.length>0" class="dropdown-menu dropdown-menu-center"
              aria-labelledby="navbarDropdownMenuLink">
            <li *ngFor="let category of categories"><a class="dropdown-item p-2" href="#" routerLink="products/{{category.url}}">{{category.name}}</a></li>
          </ul>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/contact']">Napište nám</a>
        </li>
      </ul>

      <ul class="navbar-nav mx-auto ">
        <div class="ms-auto d-none d-md-block">
          <div class="input-group">
            <span class="input-group-text bg-primary text-white"><fa-icon [icon]="faSearch"></fa-icon></span>
            <input type="text" placeholder="Vyhledat"
                   class="form-control" maxlength="22"
                   [(ngModel)]="searchByName"
                   (ngModelChange)="searchBy(searchByName)"
                   autofocus/>
            <datalist id="showDataList">
              <option value="{{filterProduct.name}}" *ngFor="let filterProduct of products">
            </datalist>
          </div>
        </div>
      </ul>

      <ul class="navbar-nav ms-auto">
        <li class="nav-item">
          <span *ngIf="productsInBasket.length > 0" class="cart-counter">{{productsInBasket.length}}</span>
          <a class="nav-link" [routerLink]="['/cart']" (click)="activeCartStep()">
            <fa-icon [icon]="faBasket"></fa-icon>&nbsp;Košík</a>
        </li>
        <div class="minicart_note" [@hideShowAnimator]="showEmptyCart"
             (@hideShowAnimator.done)="showEmptyCart = false">
          Váš košík je momentálně prázdný
        </div>
      </ul>
    </div>
  </div>
</nav>

<div class="container">
  <div class="row" [@filterAnimation]="productTotal">
    <div class="col">
      <div class="card mx-auto" *ngFor="let filterProduct of products">
        <div class="card-body" (click)="productDetail(filterProduct)">
          <div class="row align-items-center">
            <div class="col-3">
              <img [src]="getImage(filterProduct.file)"
                   class="img-fluid me-auto mx-auto" alt="Shopping item">
            </div>
            <div class="col-7">
              <h5>{{filterProduct.name}}</h5>
            </div>
            <div class="col-2">
              <span class="small d-inline-flex text-start">{{filterProduct.priceAmount}}&nbsp;Kč</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

