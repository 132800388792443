import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Product} from '../model/product/product';
import {ProductFile} from '../model/product/product-file';
import {AppConstants} from '../app-constants';
import {ProductService} from '../service/product.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {ErrorDto} from '../common/abstract/base-service';
import {faShoppingCart} from '@fortawesome/free-solid-svg-icons/faShoppingCart';
import {BasketSharedService} from '../service/basket-shared.service';
import {faArrowLeft} from '@fortawesome/free-solid-svg-icons';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.scss']
})
export class ProductDetailComponent implements OnInit, OnDestroy {

  product = new Product();
  productFile = new ProductFile(null, 'image/jpeg');
  destroy$: Subject<boolean> = new Subject<boolean>();
  errorDto: ErrorDto = new ErrorDto();
  faShoppingBasket = faShoppingCart;
  faArrowLeft = faArrowLeft;

  constructor(private actRoute: ActivatedRoute,
              private productService: ProductService,
              private spinner: NgxSpinnerService,
              private basketService: BasketSharedService) {
  }

  ngOnInit(): void {
    this.actRoute.paramMap.subscribe(params => {
      this.findProduct(params.get('productUrl'));
      this.findProductImage(params.get('productUrl'));
    });
  }

  findProduct(productUrl: string) {
    this.spinner.show().then(r => r);
    this.productService.findProduct(productUrl, this.errorDto)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: response => {
          this.spinner.hide().then(() => this.product = response.data);
        },
        error: () => {
          this.spinner.hide().then(r => r);
        }
      });
  }

  findProductImage(productUrl: string) {
    this.spinner.show().then(r => r);
    this.productService.findProductImage(productUrl, this.errorDto)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: response => {
          this.spinner.hide().then(() => this.productFile = response.data);
        },
        error: () => {
          this.spinner.hide().then(r => r);
        }
      });
  }

  addToCart(product: Product) {
    this.basketService.addToBasket(product);
  }

  public getImage(file: ProductFile): string {
    if (!!file.bytes && !!file.type) {
      return 'data:' + file.type + ';base64,' + file.bytes;
    }
    return AppConstants.emptyDefaultImage;
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
