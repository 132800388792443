import {Component, EventEmitter, Output} from '@angular/core';
import {ProductSortType} from "../model/filter/product-sort-type";

@Component({
  selector: 'app-enum-drop-down',
  templateUrl: './enum-drop-down.component.html'
})
export class EnumDropDownComponent {

  protected enumValues: Array<ProductSortType> = Object.values(ProductSortType);
  sortOrder: ProductSortType = ProductSortType.PRICE;
  @Output()
  protected sortEmittedValue = new EventEmitter<ProductSortEmittedVo>;


  emitProductSortValueChanged($event: Event) {
    console.log('emitProductSortValueChanged ', this.sortOrder);
    this.sortEmittedValue.emit({sortType: this.sortOrder});
  }
}

export class ProductSortEmittedVo {
  sortType: ProductSortType;
}
