import {faPhone} from '@fortawesome/free-solid-svg-icons/faPhone';
import {
  faArrowLeft,
  faEnvelope,
  faHome,
  faMinus,
  faPlus,
  faSearch,
  faShield,
  faShippingFast,
  faShoppingCart, faTrash,
  faUser
} from '@fortawesome/free-solid-svg-icons';
import {HttpHeaders} from '@angular/common/http';

export class AppConstants {
  public static iconPhone = faPhone;
  public static iconEnvelope = faEnvelope;
  public static iconSearch = faSearch;
  public static iconShipping = faShippingFast;
  public static iconPolicy = faShield;
  public static iconBasket = faShoppingCart;
  public static iconUser = faUser;
  public static iconHome = faHome;
  public static iconPlus = faPlus;
  public static iconMinus = faMinus;
  public static iconBin = faTrash;
  public static iconLeft = faArrowLeft
  public static get baseUrl(): string {
    //return 'http://localhost:8080';
    return 'https://waldashop.herokuapp.com';
  }

  public static get acceptJson(): HttpHeaders {
    return new HttpHeaders()
      .set('Accept', 'application/json');
  }

  public static get contentTypeAndAcceptJson(): HttpHeaders {
    return this.acceptJson
      .set('Content-Type', 'application/json');
  }

  public static get emptyDefaultImage(): string {
    return '/assets/images/empty-product.png';
  }

  public static get postOfficeDelivery(): string {
    return '/assets/images/delivery-type/posta.jpg';
  }
}
