import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {Product} from '../model/product/product';
import {LocalStorageService} from './local-storage.service';

@Injectable({
    providedIn: 'root'
})
export class BasketSharedService {

    private subjectProductsStream = new BehaviorSubject<Array<Product>>([]);

    constructor(localStorage: LocalStorageService) {
        const data = JSON.parse(localStorage.getItem('basket'));
        if (data == null) {
            this.subjectProductsStream.next([]);
        } else {
            this.subjectProductsStream.next(JSON.parse(localStorage.getItem('basket')));
        }
    }

    addToBasket(product: Product) {
        const containProduct = this.subjectProductsStream.getValue().filter(data => data.url === product.url).length > 0;
        if (!containProduct) {
            product.quantity = 1;
            this.subjectProductsStream.getValue().push(product);
            const data = this.subjectProductsStream.getValue();
            localStorage.setItem('basket', JSON.stringify(data));
            this.subjectProductsStream.next(data);
        }
    }

    addQuantity(product: Product) {
        product.quantity += 1;
        const updatedItemIndex = this.subjectProductsStream.getValue().findIndex(item => item.url === product.url);
        this.subjectProductsStream.getValue()[updatedItemIndex] = product;
        localStorage.setItem('basket', JSON.stringify(this.subjectProductsStream.getValue()));
    }

    removeQuantity(product: Product) {
        product.quantity -= 1;
        const updatedItemIndex = this.subjectProductsStream.getValue().findIndex(item => item.url === product.url);
        this.subjectProductsStream.getValue()[updatedItemIndex] = product;
        localStorage.setItem('basket', JSON.stringify(this.subjectProductsStream.getValue()));
    }

    removeFromBasket(product: Product) {
        console.log('remove from basket', product);
        const updatedArray = this.subjectProductsStream.getValue().filter(data => data.url !== product.url);
        localStorage.setItem('basket', JSON.stringify(updatedArray));
        this.subjectProductsStream.next(updatedArray);
    }

    getBasket(): Observable<Array<Product>> {
        return this.subjectProductsStream.asObservable();
    }
}
