import {Component, EventEmitter, Output} from '@angular/core';
import {LabelType, NgxSliderModule, Options} from "@angular-slider/ngx-slider";

@Component({
  selector: 'app-ngx-slider-wrapper',
  standalone: true,
  imports: [
    NgxSliderModule
  ],
  templateUrl: './ngx-slider-wrapper.component.html',
  styleUrl: './ngx-slider-wrapper.component.scss'
})
export class NgxSliderWrapperComponent {
  minValue: number = 0;
  maxValue: number = 30000;
  @Output() emittedValue = new EventEmitter<SliderEmittedVo>

  private defaultCurrency = 'CZK'

  options: Options = {
    floor: 0,
    ceil: 30000,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return "<b>Min price:</b> " + value + ' ' + this.defaultCurrency;
        case LabelType.High:
          return "<b>Max price:</b> " + value + ' ' + this.defaultCurrency;
        default:
          return value + ' ' + this.defaultCurrency;
      }
    }
  };

  emmitSliderEvent() {
    console.log('emittedValue', {minValue: this.minValue, maxValue: this.maxValue})
    this.emittedValue.emit({minValue: this.minValue, maxValue: this.maxValue})
  }
}

export class SliderEmittedVo {
  minValue: number;
  maxValue: number;
}
