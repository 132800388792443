import {Component, OnDestroy, OnInit} from '@angular/core';
import {Product} from '../model/product/product';
import {Subject} from 'rxjs';
import {BasketSharedService} from '../service/basket-shared.service';
import {PaymentSharedService} from '../service/payment-shared.service';
import {PaymentModel} from '../model/delivery/payment-model';
import {PaymentType} from '../model/delivery/payment-type';
import {DeliveryModel} from '../model/delivery/delivery-model';
import {HeaderSharedService} from '../service/header-shared.service';
import {HeaderDto} from '../model/header/header-dto';
import {AppConstants} from '../app-constants';
import {Route} from '@angular/router';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit, OnDestroy {

  isEmpty = true;
  products: Array<Product> = [];
  paymentModel = PaymentModel.bankTransfer;
  deliveryModel = DeliveryModel.packageToMall;
  totalPrice = 0;
  destroy$: Subject<boolean> = new Subject<boolean>();
  faPlus = AppConstants.iconPlus
  faMinus = AppConstants.iconMinus
  faDelete = AppConstants.iconBin;
  faArrowLeft = AppConstants.iconLeft;
  constructor(private basketService: BasketSharedService,
              private deliveryService: PaymentSharedService,
              private headerSharedService: HeaderSharedService) {
  }

  ngOnInit(): void {
    this.basketService.getBasket().subscribe(
      msg => {
        this.products = msg;
        this.products.map(product => this.totalPrice += product.priceAmount * product.quantity);
      }
    );
    this.headerSharedService.getHeaderGuideAsObservable().subscribe(data => console.log(data));

    this.deliveryService.getPaymentObservable().subscribe({
      next: paymentType => {
        switch (paymentType) {
          case PaymentType.PROFORMA:
            this.paymentModel = PaymentModel.proforma;
            break;
          default: {
            this.paymentModel = PaymentModel.bankTransfer;
            break;
          }
        }
      },
      error: err => {
        console.error('Error when obtain payment model', err);
        this.paymentModel = PaymentModel.bankTransfer;
      }
    });

    console.log('Selected last product in cart component  is', this.products.length);
  }

  getPrice(product: Product) {
    return product.priceAmount * product.quantity;
  }

  getTotalPrice() {
    this.totalPrice = 0;
    this.products.forEach(data => this.totalPrice += data.priceAmount * data.quantity);
  }

  getItem() {
    switch (this.products.length) {
      case 1 :
        return 'položku';
      case 2:
        return 'položky';
      case 3:
        return 'položky';
      case 4:
        return 'položky';
      default:
        return 'položek';
    }
  }

  removeBasket(product: Product) {
    this.basketService.removeFromBasket(product);
    this.getTotalPrice();
  }

  addQuantity(product: Product) {
    if (product.quantity >= 10) {
      return;
    }
    this.basketService.addQuantity(product);
    this.getTotalPrice();
  }

  removeQuantity(product: Product) {
    if (product.quantity < 2) {
      product.quantity = 1;
      return;
    }
    this.basketService.removeQuantity(product);
    this.getTotalPrice();
  }

  ngOnDestroy() {
    this.destroy$.complete();
    this.destroy$.next(true);
  }

  asImage(product: Product) {
    return 'data:image/jpeg;base64,' + product.file.bytes;
  }

  activePaymentStep() {
    const headerDto = new HeaderDto();
    headerDto.stepCart = false;
    headerDto.showHeaderGuide = true;
    headerDto.address = false;
    headerDto.deliveryAndPayment = true;
    this.headerSharedService.updateValue(headerDto);
  }
}
