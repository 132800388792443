import {BrowserModule} from '@angular/platform-browser';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {ProductListComponent} from './product-list/product-list.component';
import {HttpClientModule} from '@angular/common/http';
import {AppRoutingModule} from './app-routing.module';
import {FooterComponent} from './footer/footer.component';
import {HeaderComponent} from './header/header.component';
import {FormsModule} from '@angular/forms';
import {HighlightDirective} from './directive/highlight.directive';
import {ContactComponent} from './contact/contact.component';
import { EmailDirectiveDirective } from './directive/email-directive.directive';
import { CartComponent } from './cart/cart.component';
import { AddressComponent } from './address/address.component';
import { DeliveryComponent } from './delivery/delivery.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ProductDetailComponent } from './product-detail/product-detail.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AdvertisingComponent } from './advertising/advertising.component';
import { TermsComponent } from './terms/terms.component';
import {NgxSliderModule} from "@angular-slider/ngx-slider";
import {NgxSliderWrapperComponent} from "./ngx-slider-wrapper/ngx-slider-wrapper.component";
import {ProductListHomeComponent} from "./product-list-home/product-list-home.component";
import {EnumDropDownComponent} from "./enum-drop-down-form/enum-drop-down.component";
import {VariantCatalogFilterComponent} from "./variant-catalog-filter/variant-catalog-filter.component";
import {VariantCatalogFilterItemComponent} from "./variant-catalog-filter-item/variant-catalog-filter-item.component";
import {NgxPaginationModule} from "ngx-pagination";

@NgModule({
  imports: [
    NgxSpinnerModule,
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    FontAwesomeModule,
    BrowserAnimationsModule,
    NgxSliderModule,
    NgxSliderWrapperComponent,
    NgxPaginationModule
  ],
  exports: [NgxSpinnerModule, ProductListComponent, EnumDropDownComponent],
  declarations: [
    AppComponent,
    ProductListComponent,
    ProductListHomeComponent,
    ProductDetailComponent,
    FooterComponent,
    HeaderComponent,
    HighlightDirective,
    ContactComponent,
    EmailDirectiveDirective,
    CartComponent,
    AddressComponent,
    DeliveryComponent,
    AdvertisingComponent,
    TermsComponent,
    EnumDropDownComponent,
    VariantCatalogFilterComponent,
    VariantCatalogFilterItemComponent
  ],
  schemas:[CUSTOM_ELEMENTS_SCHEMA],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
