<div class="container">
    <div class="footer border-top py-2">
        <div class="text-center text-md-start mt-5">
            <div class="row mt-3">
                <div class="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
                    <h6 class="text-uppercase fw-bold mb-4">
                        <span class="d-block text-center">Comic-shop</span>
                    </h6>
                    <p>
                        Tato stránka je určena převážně nadšencům do komiksů. K dispozici máme i speciální vydavatelské
                        edice, kterých není mnoho.
                    </p>
                </div>
                <div class="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
                    <!-- Links -->
                    <h6 class="text-uppercase fw-bold mb-4">
                        Products
                    </h6>
                    <p>
                        <a href="#!" class="text-reset">Angular</a>
                    </p>
                    <p>
                        <a href="#!" class="text-reset">React</a>
                    </p>
                    <p>
                        <a href="#!" class="text-reset">Vue</a>
                    </p>
                    <p>
                        <a href="#!" class="text-reset">Laravel</a>
                    </p>
                </div>
                <!-- Grid column -->

                <!-- Grid column -->
                <div class="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
                    <!-- Links -->
                    <h6 class="text-uppercase fw-bold mb-4">
                        Užitečné&nbsp;odkazy
                    </h6>
                    <p>
                        <a href="/advertising" class="text-reset">Reklamační&nbsp;řád</a>
                    </p>
                    <p>
                        <a href="/terms" class="text-reset">Obchodní&nbsp;podmínky</a>
                    </p>
                    <p>
                        <a href="#!" class="text-reset">Help</a>
                    </p>
                </div>
                <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
                    <h6 class="text-uppercase fw-bold mb-4">
                        Kontakt
                    </h6>
                    <p><span class="d-inline"><fa-icon [icon]="faHome" class="me-2"></fa-icon>
                     <span class="d-lg-inline-block d-xs-block me-3">Česká&nbsp;Republika</span>
                     <span class="d-block px-3">&nbsp;&nbsp;&nbsp;Sokolnická&nbsp;321</span>
                     <span class="d-block px-5">665&nbsp;30</span>
                  </span>
                    </p>
                    <p>
                        <fa-icon [icon]="faEnvelope" class="me-3"></fa-icon>
                        comic&#64;shop.com
                    </p>
                    <p>
                        <fa-icon [icon]="faPhone" class="me-3"></fa-icon>
                        +420&nbsp;725&nbsp;405&nbsp;962
                    </p>
                </div>
            </div>
        </div>
    </div>

</div>
<!-- Copyright -->
<div class="text-center text-light bg-dark p-4 w-100">
    © 2022 Copyright:
    <a class="text-reset fw-bold" href="https://mdbootstrap.com/">Comic Shop</a>
</div>
<!-- Copyright -->
