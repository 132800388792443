<div class="container pt-4">
  <h1 class="text-center">Reklamační&nbsp;řád</h1>
  <p class="py-3">
    Tento reklamační řád se vztahuje na prodej zboží <span class="font-monospace">{{advertisementDto.who}}</span>
    IČO: <span class="font-monospace">{{advertisementDto.ico}}</span> , se sídlem na adrese <span
    class="font-monospace">{{advertisementDto.address}}</span>
    (dále jako <span class="fw-bold">prodávající</span>).
  </p>
  <ol class="list-group list-group-numbered">
    <li class="list-group-item pt-3"><span class="fw-bold">Záruka za jakost.</span>
      <ol class="list-group list-group-numbered pt-3">
        <li class="list-group-item ">Prodávající odpovídá kupujícímu, že věc při převzetí nemá vady. Zejména
          prodávající odpovídá kupujícímu, že v době, kdy kupující věc převzal:
          <ol class="list-group list-group-numbered pt-3 border-0">
            <li class="list-group-item border-0">má věc vlastnosti, které si strany ujednaly, a chybí-li ujednání
              takové vlastnosti, pak takové, které prodávající nebo výrobce popsal nebo které kupující očekával s
              ohledem
              na
              povahu zboží a na základě reklamy prodávajícího a/nebo výrobce;
            </li>
            <li class="list-group-item border-0">se věc hodí k účelu, který pro její použití prodávající uvádí nebo ke
              kterému se
              věc
              tohoto druhu obvykle používá;
            </li>
            <li class="list-group-item border-0">je věc v odpovídajícím množství, míře nebo hmotnosti;</li>
            <li class="list-group-item border-0">vyhovuje věc požadavkům právních předpisů.</li>
          </ol>
        </li>
        <li class="list-group-item">Kupující je povinen při osobním převzetí od prodávajícího zkontrolovat přebírané
          zboží,
          jeho úplnost a nepoškozenost obalů.
        </li>
        <li class="list-group-item">Kupující je povinen při převzetí od přepravce řádně zkontrolovat stav přebíraného
          zboží,
          jeho úplnost a nepoškozenost obalů podle přepravního listu.
        </li>
      </ol>
    </li>
    <li class="list-group-item pt-3"><span class="fw-bold">Způsob uplatnění reklamace.</span>
      <ol class="list-group list-group-numbered pt-3">
        <li class="list-group-item"> Práva kupujícího z vadného plnění (dále jako „<span
          class="fw-bold">reklamace</span>“) musí být uplatněna v souladu s tímto reklamačním řádem.
        </li>
        <li class="list-group-item"> Kupující má právo uplatnit reklamaci u prodávajícího, a to na
          adrese: <span class="fw-bold">{{advertisementDto.address}}</span></li>
        <li class="list-group-item"> Prodávající je povinen zabezpečit přítomnost pracovníka pověřeného přijímat
          reklamace po celou provozní dobu.
        </li>
        <li class="list-group-item">Kupující má právo uplatnit reklamaci u osoby, která je k tomu určena v příslušném
          záručním listě a/nebo v přislušném daňovém dokladu, je-li určená osoba v místě prodávajícího nebo v místě
          kupujícímu bližším.
        </li>
        <li class="list-group-item">Kupující je povinen při reklamaci doložit datum koupě daného zboží, a to zejména
          předložením příslušného daňového dokladu a/nebo záručního listu, popř. jiným věrohodným způsobem.
        </li>
        <li class="list-group-item">Kupující nemá právo uplatnit reklamaci na vadu, která byla vytýkána již v minulosti,
          pokud na ni byla poskytnuta přiměřená sleva z kupní ceny daného zboží.
        </li>
      </ol>
    </li>
    <li class="list-group-item pt-3"><span class="fw-bold">Lhůty pro uplatnění a vyřízení reklamace.</span>
      <ol class="list-group list-group-numbered pt-3">
        <li class="list-group-item">Poskytl-li prodávající nad rámec zákonných povinností (zejména zákonné lhůty) záruku
          za jakost, její uplatnění se řídí tímto reklamačním řádem, pokud záruční list nebo smlouva nestanoví jinak.
        </li>
        <li class="list-group-item">Lhůta pro uplatnění reklamace začíná běžet dnem převzetí zboží kupujícím, který je
          uveden v daňovém dokladu nebo na záručním listě či jiném takovém dokumentu.
        </li>
        <li class="list-group-item">Záruční lhůta pro <span class="fw-bold">{{advertisementDto.consumer}}</span> činí pro nové zboží
          <span class="fw-bold">{{advertisementDto.complaintDays}}</span>.
        </li>
        <li class="list-group-item">Prodávající poskytuje záruku kupujícímu, který není spotřebitelem (tj. pro
          podnikatele), v případě, že je to u daného zboží výslovně uvedeno.
        </li>
        <li class="list-group-item">U spotřebního zboží je kupující oprávněn uplatnit právo z vad pouze do data
          vyznačeného na obalu takového zboží, je-li tato lhůta kratší než lhůta uvedená v odst. 3.3 tohoto reklamačního
          řádu
        </li>
        <li class="list-group-item">Kupující je povinen reklamovat zboží bez zbytečného odkladu poté, co zjistí, že je
          na zboží vada. Prodávající neodpovídá za zvětšení rozsahu poškození, pokud kupující zboží užívá, ačkoliv o
          vadě ví.
        </li>
        <li class="list-group-item">Reklamuje-li kupující zboží oprávněně, neběží lhůta pro reklamaci po dobu, po kterou
          je zboží v opravě, a kupující jej nemůže užívat.
        </li>
        <li class="list-group-item">Prodávající je povinen o reklamaci rozhodnout ihned, ve složitějších případech
          do <span class="fw-bold">{{advertisementDto.complaintDays}}</span>. Do této lhůty se nezapočítává doba potřebná k
          odbornému posouzení vady.
          Prodávající je povinen vydat kupujícímu písemné potvrzení, ve kterém bude uvedeno datum a místo uplatnění
          reklamace, charakteristika vytýkané vady, požadovaný způsob vyřízení reklamace a způsob, jakým bude kupující
          informován o jejím vyřízení. Reklamace včetně odstranění vady musí být vyřízena bez zbytečného odkladu,
          nejpozději do <span class="fw-bold">{{advertisementDto.complaintDaysDelayed}}</span> ode dne uplatnění reklamace, pokud se
          prodávající s kupujícím nedohodne
          na delší lhůtě. Marné uplynutí
          této lhůty se považuje za podstatné porušení smlouvy. Způsob vyřízení reklamace a dobu jejího trvání je
          prodávající povinen kupujícímu písemně potvrdit. Kupující není oprávněn bez souhlasu prodávajícího měnit
          jednou zvolený způsob vyřízení reklamace vyjma situace, kdy jím zvolený způsob řešení není možné vůbec nebo
          včas uskutečnit.
        </li>
        <li class="list-group-item">Je-li reklamace uznána za oprávněnou, má kupující právo na úhradu účelně
          vynaložených nákladů spojených s uplatněním reklamace.
        </li>
        <li class="list-group-item">V případě vyřešení reklamace výměnou zboží neběží nová lhůta pro uplatnění práv z
          vadného plnění, ale pokračuje běh lhůty reklamovaného zboží.
        </li>
      </ol>
    </li>
    <li class="list-group-item pt-3"><span class="fw-bold">Výjimky z odpovědnosti za vady.</span>
      <ol class="list-group list-group-numbered pt-3">
        <li class="list-group-item pt-3">Prodávající neodpovídá za vady zboží v následujících případech:
          <ol class="list-group list-group-numbered pt-3">
            <li class="list-group-item border-0">je-li vada na zboží v době převzetí a pro takovou vadu je sjednána
              sleva z kupní
              ceny;
            </li>
            <li class="list-group-item border-0">je způsobena kupujícím a vznikla nesprávným užíváním, skladováním,
              nesprávnou
              údržbou, zásahem kupujícího či mechanickým poškozením zboží;
            </li>
            <li class="list-group-item border-0">vada na zboží vznikla opotřebením způsobeným obvyklým užíváním nebo
              vyplývá-li
              to z povahy věci;
            </li>
            <li class="list-group-item border-0">jde-li o zboží použité a vada odpovídá míře používání nebo opotřebení,
              které
              mělo zboží v době převzetí zboží kupujícím;
            </li>
            <li class="list-group-item border-0">mechanické poškození zboží;</li>
            <li class="list-group-item border-0">vada vznikla neodbornou instalací, zacházením, obsluhou nebo zanedbáním
              péče o
              zboží;
            </li>
            <li class="list-group-item border-0">provedení nekvalifikovaného zásahu či změna parametrů;</li>
            <li class="list-group-item border-0">používání zboží v podmínkách, které neodpovídají svojí teplotou,
              prašností,
              vlhkostí, chemickými a mechanickými vlivy prostředí, které je prodejcem nebo výrobcem určeno;
            </li>
            <li class="list-group-item border-0">poškození v důsledku vyšší moci;</li>
          </ol>
        </li>
      </ol>
    </li>
    <li class="list-group-item pt-3"><span class="fw-bold">Závěrečná ustanovení.</span>
      <ol class="list-group list-group-numbered pt-3">
        <li class="list-group-item">Ve vztahu ke splnění povinnosti dle §14 zákona č. 634/1992 Sb. prodávající informuje
          kupujícího o možnosti využít pro případné mimosoudní řešení spotřebitelských sporů Českou obchodní inspekci <a
            href="https://www.coi.cz"> coi</a>, jež je notifikovaným subjektem mimosoudního řešení spotřebitelských
          sporů vedeným na seznamu Evropské komise.
        </li>
      </ol>
    </li>
    <li class="list-group-item pt-3"><span class="fw-bold">Účinnost.</span>
      <ol class="list-group">
        <li class="list-group-item border-0">
          Tento reklamační řád nabývá účinnosti dne <span class="fw-bold">{{advertisementDto.dueDate | date: 'dd.MM.yyyy'}}</span>
        </li>
      </ol>
    </li>
  </ol>
</div>


