export class Advertisement {
  who: string;
  address: string;
  complaintDays: string;
  consumer: string;
  complaintDaysDelayed: string;
  dueDate: Date;
  ico: string;
  phone: string;
  emailWho: string;
  eshopEmail: string;
  webEshopAddress: string;
  sortiment: string;
  eshopName: string;
  limitFreeShippingPrice: string;
}
