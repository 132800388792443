<div class="">
  <span>Sort by:</span>
  <select class="form-select" [(ngModel)]="sortOrder" (change)="emitProductSortValueChanged($event)">
    <option *ngFor="let enumValue of enumValues" [value]="enumValue">{{ enumValue }}</option>
  </select>
</div>





