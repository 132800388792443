<div class="card">
        <h4 class="mb-3">Dodací&nbsp;adresa</h4>
        <form #createOrderForm="ngForm" novalidate
              (ngSubmit)="createOrderForm.form.valid && createOrder()">
            <div class="row">
                <div class="col-md-6 mb-3">
                    <label for="sFirstName">Jméno</label>
                    <input type="text" class="form-control" id="sFirstName" placeholder="required" value=""
                           minlength="3" required
                           name="sName" [(ngModel)]="shippingAddress.firstName" #sName="ngModel">
                    <div *ngIf="createOrderForm.submitted && sName.invalid"
                         class="alert alert-danger">
                        <div *ngIf="sName.errors.required">
                            Jméno musí být vyplněno
                        </div>
                        <div *ngIf="sName.errors.minlength">
                            Jméno musí mít alespoň 3 znaky
                        </div>
                    </div>
                </div>
                <div class="col-md-6 mb-3">
                    <label for="sLastName">Příjmení</label>
                    <input type="text" class="form-control" id="sLastName" placeholder="required" minlength="3" required
                           name="sSurname" [(ngModel)]="shippingAddress.lastName" #sSurname="ngModel">
                    <div *ngIf="createOrderForm.submitted && sSurname.invalid"
                         class="alert alert-danger">
                        <div *ngIf="sSurname.errors.required">
                            Příjmení musí být vyplněno
                        </div>
                        <div *ngIf="sSurname.errors.minlength">
                            Příjmení musí mít alespoň 3 znaky
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-8 mb-6">
                    <label for="sStreet">Ulice</label>
                    <input type="text" class="form-control" id="sStreet" placeholder="required" value="" minlength="4"
                           required
                           name="sStreet" [(ngModel)]="shippingAddress.street" #sStreet="ngModel">
                    <div *ngIf="createOrderForm.submitted && sStreet.invalid"
                         class="alert alert-danger">
                        <div *ngIf="sStreet.errors.required">
                            Musí být vyplněna
                        </div>
                        <div *ngIf="sStreet.errors.minlength">
                            Musím mít alespoň 4 znaky
                        </div>
                    </div>
                </div>
                <div class="col-md-4 mb-3">
                    <label for="sStreetNumber">Číslo</label>
                    <input type="text" class="form-control" id="sStreetNumber" placeholder="required" required
                           name="sStreetNumber" [(ngModel)]="shippingAddress.streetNumber" #sStreetNumber="ngModel">
                    <div *ngIf="createOrderForm.submitted && sStreetNumber.invalid"
                         class="alert alert-danger">
                        <div *ngIf="sStreetNumber.errors.required">
                            Musím být vyplněno
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-7 mb-3">
                    <label for="sCity">Město</label>
                    <input type="text" class="form-control" id="sCity" placeholder="required" minlength="2" required
                           name="sCity" [(ngModel)]="shippingAddress.city" #sCity="ngModel">
                    <div *ngIf="createOrderForm.submitted && sCity.invalid"
                         class="alert alert-danger">
                        <div *ngIf="sCity.errors.required">
                            Musím být vyplněno
                        </div>
                        <div *ngIf="sCity.errors.minlength">
                            Zadej alespoň 2 znaky
                        </div>
                    </div>
                </div>
                <div class="col-md-5 mb-3">
                    <label for="sZipCode">PSČ</label>
                    <input type="text" class="form-control" id="sZipCode" placeholder="required" minlength="3" required
                           name="sZipCode" [(ngModel)]="shippingAddress.zipCode" #sZipCode="ngModel">
                    <div *ngIf="createOrderForm.submitted && sZipCode.invalid"
                         class="alert alert-danger">
                        <div *ngIf="sZipCode.errors.required">
                            Musím být vyplněno
                        </div>
                        <div *ngIf="sZipCode.errors.minlength">
                            Zadej alespoň 3 znaky
                        </div>
                    </div>
                </div>
            </div>
            <div class="mb-3">
                <label for="sState">Stát</label>
                <input type="text" class="form-control" id="sState" [readOnly]="true" [value]="shippingAddress.country"
                       name="sCountry" [(ngModel)]="shippingAddress.country" #sCountry="ngModel">
            </div>

            <div class="mb-3">
                <label for="sPhone">Telefon</label>
                <input type="text" class="form-control" id="sPhone" minlength="9" placeholder="required" required
                       name="sPhone" [(ngModel)]="shippingAddress.phone" #sPhone="ngModel">
                <div *ngIf="createOrderForm.submitted && sPhone.invalid"
                     class="alert alert-danger">
                    <div *ngIf="sPhone.errors.required">
                        Musím být vyplněno
                    </div>
                    <div *ngIf="sPhone.errors.minlength">
                        Zadej alespoň 9 znaků
                    </div>
                </div>
            </div>

            <label>
                <input type="checkbox" [value]="order.billingAsShipping"
                       name="sameBillingAndShipping" [(ngModel)]="order.billingAsShipping"
                       #sameBillingAndShipping="ngModel">
                <span class="checkmark">&nbsp;&nbsp;Fakturační&nbsp;adresa&nbsp;je&nbsp;stejná</span>
            </label>

            <div *ngIf="!order.billingAsShipping" id="billing"><br>
                <h4 class="mb-3">Billing address</h4>
                <div class="row">
                    <div class="col-md-6 mb-3">
                        <label for="bFirstName">Jméno</label>
                        <input type="text" class="form-control" id="bFirstName" placeholder="required" value="" required
                               name="bName" [(ngModel)]="billingAddress.firstName" #bName="ngModel">
                    </div>
                    <div *ngIf="createOrderForm.submitted && bName.invalid"
                         class="alert alert-danger">
                        <div *ngIf="bName.errors.required">
                            Jméno musí být vyplněno
                        </div>
                        <div *ngIf="bName.errors.minlength">
                            Jméno musí mít alespoň 3 znaky
                        </div>
                    </div>
                    <div class="col-md-6 mb-3">
                        <label for="bLastName">Příjmení</label>
                        <input type="text" class="form-control" id="bLastName" placeholder="required" value="" required
                               name="bLastName" [(ngModel)]="billingAddress.lastName" #bLastName="ngModel">
                    </div>
                    <div *ngIf="createOrderForm.submitted && bLastName.invalid"
                         class="alert alert-danger">
                        <div *ngIf="bLastName.errors.required">
                            Příjmení musí být vyplněno
                        </div>
                        <div *ngIf="bLastName.errors.minlength">
                            Příjmení musí mít alespoň 3 znaky
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-8 mb-6">
                        <label for="bStreet">Ulice</label>
                        <input type="text" class="form-control" id="bStreet" placeholder="required" required
                               name="bStreet" [(ngModel)]="billingAddress.street" #bStreet="ngModel">
                    </div>
                    <div *ngIf="createOrderForm.submitted && sStreet.invalid"
                         class="alert alert-danger">
                        <div *ngIf="bStreet.errors.required">
                            Musí být vyplněna
                        </div>
                        <div *ngIf="bStreet.errors.minlength">
                            Musím mít alespoň 4 znaky
                        </div>
                    </div>
                    <div class="col-md-4 mb-3">
                        <label for="bStreetNumber">Číslo</label>
                        <input type="text" class="form-control" id="bStreetNumber" placeholder="required" required
                               name="bStreetNumber" [(ngModel)]="billingAddress.streetNumber" #bStreetNumber="ngModel">
                    </div>
                    <div *ngIf="createOrderForm.submitted && bStreetNumber.invalid"
                         class="alert alert-danger">
                        <div *ngIf="bStreetNumber.errors.required">
                            Musím být vyplněno
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-7 mb-3">
                        <label for="bTown">Město</label>
                        <input type="text" class="form-control" id="bTown" placeholder="required" required
                               name="bCity" [(ngModel)]="billingAddress.city" #bCity="ngModel">
                    </div>
                    <div *ngIf="createOrderForm.submitted && bCity.invalid"
                         class="alert alert-danger">
                        <div *ngIf="bCity.errors.required">
                            Musím být vyplněno
                        </div>
                        <div *ngIf="bCity.errors.minlength">
                            Zadej alespoň 2 znaky
                        </div>
                    </div>
                    <div class="col-md-5 mb-3">
                        <label for="bZipCode">PSČ</label>
                        <input type="text" class="form-control" id="bZipCode" placeholder="required" required
                               name="bZipCode" [(ngModel)]="billingAddress.zipCode" #bZipCode="ngModel">
                    </div>
                    <div *ngIf="createOrderForm.submitted && bZipCode.invalid"
                         class="alert alert-danger">
                        <div *ngIf="bZipCode.errors.required">
                            Musím být vyplněno
                        </div>
                        <div *ngIf="bZipCode.errors.minlength">
                            Zadej alespoň 3 znaky
                        </div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="bState">Stát</label>
                    <input type="text" class="form-control" id="bState" [readOnly]="true"
                           [value]="billingAddress.country"
                           name="bCountry" [(ngModel)]="billingAddress.country" #bCountry="ngModel">
                </div>
                <div class="mb-3">
                    <label for="bPhone">Telefon</label>
                    <input type="text" class="form-control" id="bPhone" required placeholder="required"
                           name="bPhone" [(ngModel)]="billingAddress.phone" #bPhone="ngModel">
                </div>
                <div *ngIf="createOrderForm.submitted && bPhone.invalid"
                     class="alert alert-danger">
                    <div *ngIf="bPhone.errors.required">
                        Musím být vyplněno
                    </div>
                    <div *ngIf="bPhone.errors.minlength">
                        Zadej alespoň 9 znaků
                    </div>
                </div>
            </div>

            <div class="mb-3">
                <label for="email">Email </label>
                <input type="email" class="form-control" id="email" name="email" placeholder="required" emailApp
                       [(ngModel)]="order.email" #email="ngModel">
            </div>
            <div *ngIf="createOrderForm.submitted && email.invalid"
                 class="alert alert-danger">
                <div *ngIf="email.errors.isInvalidEmail">
                    Email is invalid
                </div>
            </div>

            <button type="submit" class="float-lg-right btn btn-info">&nbsp;Dokončit objednávku</button>
        </form>
    </div>
