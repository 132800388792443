import {Injectable} from '@angular/core';
import {BaseService, ErrorDto} from '../common/abstract/base-service';
import {HttpClient} from '@angular/common/http';
import {catchError} from 'rxjs/operators';
import {WriteUs} from '../model/email/write-us';
import {WriteUsRequest} from '../model/email/write-us-request';
import {AppConstants} from '../app-constants';

@Injectable({
  providedIn: 'root'
})
export class EmailService extends BaseService {

  private writeUsEndpoint = AppConstants.baseUrl + '/api/email/write-us';

  constructor(private httpClient: HttpClient) {
    super();
  }

  public contactUs(writeUs: WriteUs, errorDto: ErrorDto) {
    const body = JSON.stringify(new WriteUsRequest(writeUs));
    return this.httpClient.post(this.writeUsEndpoint, body,
      {
        headers: AppConstants.contentTypeAndAcceptJson
      })
      .pipe(catchError((err) => this.handleError(err, errorDto)));
  }
}
