import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {WriteUs} from '../model/email/write-us';
import {Subject} from 'rxjs';
import {EmailService} from '../service/email.service';
import {takeUntil} from 'rxjs/operators';
import {NgForm} from '@angular/forms';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {ErrorDto} from '../common/abstract/base-service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit, OnDestroy {
  writeUs = new WriteUs();
  send = false;
  notificationMessage = 'Email odeslán';
  errorDto = new ErrorDto();
  clazz = 'alert alert-danger';
  @ViewChild('contactUsForm', {static: false}) myForm: NgForm;
  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private emailService: EmailService,
              private spinner: NgxSpinnerService,
              private router: Router) {
  }

  ngOnInit(): void {
  }

  contactUs() {
    this.spinner.show().then(r => r);
    this.emailService.contactUs(this.writeUs,this.errorDto)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: response => {
          console.log('Email contact us send', response);
          this.notificationMessage = 'Email odeslán';
          this.clazz = 'alert alert-success';
          this.send = true;
          this.myForm.resetForm();
          this.spinner.hide().then(() => this.deleteSuccessfulMessage());
        },
        error: err => {
          this.notificationMessage = 'Oops to je nemilé, chyba je na Naši straně.\n Funkce je dočasně mimo službu!';
          this.send = true;
          this.clazz = 'alert alert-danger';
          console.error('Email contact us failed', err);
          this.spinner.hide().then(() => this.deleteSuccessfulMessage());
        },
      });
  }

  async deleteSuccessfulMessage() {
    await new Promise(resolve => setTimeout(resolve, 4000))
      .then(() => this.router.navigateByUrl('/product-detail/'));
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
