import {Injectable} from '@angular/core';
import {BaseService, ErrorDto} from "../common/abstract/base-service";
import {AppConstants} from "../app-constants";
import {HttpClient} from "@angular/common/http";
import {VariantCatalogData} from "../model/variant-catalog/variant-catalog-data";
import {catchError} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class VariantCatalogService extends BaseService {

  private variantCatalogEndpoint = AppConstants.baseUrl + '/api/variant-catalog';

  constructor(private httpClient: HttpClient) {
    super();
  }

  public listAll(errorDto: ErrorDto) {
    return this.httpClient.get<VariantCatalogData>(this.variantCatalogEndpoint.concat("/all"), {headers: AppConstants.acceptJson})
      .pipe(catchError((err) => this.handleError(err, errorDto)));
  }
}
