import {Injectable} from '@angular/core';
import {catchError} from 'rxjs/operators';
import {HttpClient, HttpParams} from '@angular/common/http';
import {BaseService, ErrorDto} from '../common/abstract/base-service';
import {ProductFilterResponseDto} from '../model/product/product-filter-response';
import {AppConstants} from '../app-constants';
import {ProductDetailResponse} from '../model/product/product-detail-response';
import {ProductFileData} from '../model/product/product-file-data';
import {ProductSortType} from "../model/filter/product-sort-type";
import {ProductPaginationVo} from "../product-list/product-list.component";

@Injectable({
  providedIn: 'root'
})
export class ProductService extends BaseService {

  private productsFilterEndpoint = AppConstants.baseUrl + '/api/products/filter';
  private productEndpoint = AppConstants.baseUrl + '/api/products';
  private productImageEndpoint = AppConstants.baseUrl + '/api/products/img-upload';

  constructor(private httpClient: HttpClient) {
    super();
  }

  public filterProducts(errorDto: ErrorDto,
                        byName?: string,
                        byCategoryUrl?: string,
                        priceMin?: number,
                        priceMax?: number,
                        productSort?: ProductSortType,
                        filters?: Array<number>,
                        productPagination?: ProductPaginationVo) {
    let paramsData = new HttpParams()
      .set('detailed', 'true')
      .set('sortBy', productSort || ProductSortType.NAME);

    let pageNumber = productPagination?.pageNumber;
    if (pageNumber !== undefined) {
      if (pageNumber > 0) {
        paramsData = paramsData.set('pageNumber', productPagination.pageNumber - 1)
      } else {
        paramsData = paramsData.set('pageNumber', productPagination.pageNumber)
      }
      // TODO solve problem with pagination from 0 instead of 1 - does not work


    }

    if (productPagination?.pageSize !== undefined) {
      paramsData = paramsData.set('pageSize', productPagination.pageSize)
    }

    if (byName) {
      paramsData = paramsData.set('name', byName);
    }

    if (byCategoryUrl !== undefined) {
      paramsData = paramsData.set('categories', byCategoryUrl);
    }

    if (priceMin !== undefined) {
      paramsData = paramsData.set('priceMin', priceMin.toString());
    }

    if (priceMax !== undefined) {
      paramsData = paramsData.set('priceMax', priceMax.toString());
    }

    if (filters !== undefined && filters.length > 0) {
      paramsData = paramsData.set('variantIds', filters.join(','));
    }

    return this.httpClient.get<ProductFilterResponseDto>(this.productsFilterEndpoint, {
      headers: AppConstants.acceptJson,
      params: paramsData
    }).pipe(catchError((err) => this.handleError(err, errorDto)));
  }

  findProduct(productUrl: string, errorDto: ErrorDto) {
    const productUrlEndpoint = this.addParamIntoUrl(this.productEndpoint, productUrl);
    return this.httpClient.get<ProductDetailResponse>(productUrlEndpoint, {
      headers: AppConstants.acceptJson,
    }).pipe(catchError((err) => this.handleError(err, errorDto)));
  }

  findProductImage(productUrl: string, errorDto: ErrorDto) {
    const productUrlEndpoint = this.addParamIntoUrl(this.productImageEndpoint, productUrl);
    return this.httpClient.get<ProductFileData>(productUrlEndpoint, {
      headers: AppConstants.acceptJson,
    }).pipe(catchError((err) => this.handleError(err, errorDto)));
  }
}
